import {Injectable} from '@angular/core';

import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SharedSubscriptionService {
  private subject = new Subject<any>();

  sendInvokeEvent() {
    this.subject.next();
  }

  getInvokeEvent():Observable<any> {
    return this.subject.asObservable();
  }
}
