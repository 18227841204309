import {Component, EventEmitter, Input, Output} from '@angular/core';

import {
  ActiveUser,
  BaseComponent,
  ImageService,
  ListService,
  LoginService,
  RedirectService,
  RequestService,
  RouteService,
  UserService
} from '@uab.lakeshore.collaborative/next-base';
import {finalize} from 'rxjs/operators';

import {NoteService} from '../../services/note.service';
import {HardcodedQuestionModel} from '../../models/hardcoded-question.model';

@Component({
  selector: 'uab-screening-questions',
  templateUrl: 'screening-questions.component.html',
  styleUrls: [
    'screening-questions.component.scss'
  ]
})
export class ScreeningQuestionsComponent implements BaseComponent {
  @Output() onCreate:EventEmitter<ActiveUser>;
  @Output() onCancel:EventEmitter<ActiveUser>;
  @Input() hardcodedQuestions:Array<HardcodedQuestionModel> = [];
  @Input() surveyName:string = '';

  public isCreatingUserQuestionAnswer:boolean;
  public activeUser:ActiveUser;

  constructor(
    private image:ImageService,
    private list:ListService,
    private loginService:LoginService,
    private noteService:NoteService,
    private redirect:RedirectService,
    private requestService:RequestService,
    private route:RouteService,
    private userService:UserService
  ) {
    this.reset();

    this.onCreate = new EventEmitter<ActiveUser>();

    this.onCancel = new EventEmitter<ActiveUser>();
  }

  cancel():void {
   this.onCancel.emit();
  }

  createScreeningUserAnswers():void {
    this.isCreatingUserQuestionAnswer = true;

    this.hardcodedQuestions.forEach(
      (hardcodedQuestion) => {
        const model = hardcodedQuestion.modelName;
        this.activeUser[model] = hardcodedQuestion.answer;
      }
    );

    this.userService.editUser(this.activeUser).pipe(
      finalize(
        () => {
          this.isCreatingUserQuestionAnswer = false;
        }
      )
    ).subscribe(
      (response) => {
        if (response) {
          this.onCreate.emit(this.activeUser);

          this.reset();
        }
      }
    );
  }

  getActiveUser() {
    const activeUser = this.loginService.getActiveUser();
    if (activeUser) {
      this.activeUser = <ActiveUser><unknown>activeUser;
    }
  }

  reset() {
    this.getActiveUser();
  }
}
