import {NgModule} from '@angular/core';

import {RouterModule, Routes} from '@angular/router';

import {IsAtLeastAdminGuard} from './guards/is-at-least-admin.guard';
import {LoginGuard, LogoutGuard} from '@uab.lakeshore.collaborative/next-base';
import {IsAtLeastLakeshoreAdminGuard} from './guards/is-at-least-lakeshore-admin.guard';
import {IsSurveysCompleted} from './guards/is-surveys-completed';

const routes:Routes = [
  {
    path: 'adherence-report',
    loadChildren:
      () => import(
        './pages/adherence-report/adherence-report.module'
        ).then(
        m => m.AdherenceReportPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'article/:articleId',
    loadChildren:
      () => import(
        './pages/article/article.module'
        ).then(
        m => m.ArticlePageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'article-create',
    loadChildren:
      () => import(
        './pages/article-create/article-create.module'
        ).then(
        m => m.ArticleCreatePageModule
      ),
    canActivate: [
      IsAtLeastLakeshoreAdminGuard,
      LoginGuard
    ],
    data: [
      'lakeshore_admin'
    ]
  },
  {
    path: 'article-edit/:articleId',
    loadChildren:
      () => import(
        './pages/article-edit/article-edit.module'
        ).then(
        m => m.ArticleEditPageModule
      ),
    canActivate: [
      IsAtLeastLakeshoreAdminGuard,
      LoginGuard
    ],
    data: [
      'lakeshore_admin'
    ]
  },
  {
    path: 'article-list',
    loadChildren:
      () => import(
        './pages/article-list/article-list.module'
        ).then(
        m => m.ArticleListPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'article-list-matching',
    loadChildren:
      () => import(
        './pages/article-list-matching/article-list-matching.module'
        ).then(
        m => m.ArticleListMatchingPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'article-list-week',
    loadChildren:
      () => import(
        './pages/article-list-week/article-list-week.module'
        ).then(
        m => m.ArticleListWeekPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'application-management',
    loadChildren:
      () => import(
        './pages/application-management/application-management.module'
        ).then(
        m => m.ApplicationManagementPageModule
      ),
    canActivate: [
      IsAtLeastLakeshoreAdminGuard
    ]
  },
  {
    path: 'approved-user-management',
    loadChildren:
      () => import(
        './pages/approved-user-management/approved-user-management.module'
        ).then(
        m => m.ApprovedUserManagementPageModule
      ),
    canActivate: [
      IsAtLeastLakeshoreAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'research-approved-user-management',
    loadChildren:
      () => import(
        './pages/research-approved-user-management/research-approved-user-management.module'
        ).then(
        m => m.ResearchApprovedUserManagementPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'survey-pending-users',
    loadChildren:
      () => import(
        './pages/survey-pending-users-management/survey-pending-users-management.module'
        ).then(
        m => m.SurveyPendingUsersManagementModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'article-management',
    loadChildren:
      () => import(
        './pages/data-tables/article-management/article-management.module'
        ).then(
        m => m.ArticleManagementPageModule
      ),
    canActivate: [
      IsAtLeastLakeshoreAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'article-analytic-management',
    loadChildren:
      () => import(
        './pages/data-tables/article-analytic-management/article-analytic-management.module'
        ).then(
        m => m.ArticleAnalyticManagementPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'browser-check',
    loadChildren:
      () => import(
        './pages/browser-check/browser-check.module'
        ).then(
        m => m.BrowserCheckPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'contact-staff',
    loadChildren:
      () => import(
        './pages/contact-staff/contact-staff.module'
        ).then(
        m => m.ContactStaffPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'contact-staff-message-list',
    loadChildren:
      () => import(
        './pages/contact-staff-message-list/contact-staff-message-list.module'
        ).then(
        m => m.ContactStaffMessageListPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'contact-staff-message/:id',
    loadChildren:
      () => import(
        './pages/contact-staff-message/contact-staff-message.module'
        ).then(
        m => m.ContactStaffMessagePageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'default-video-list',
    loadChildren:
      () => import(
        './pages/default-video-list/default-video-list.module'
        ).then(
        m => m.DefaultVideoListPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'email-history/:userId',
    loadChildren:
      () => import(
        './pages/email-history/email-history.module'
        ).then(
        m => m.EmailHistoryPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'email-management',
    loadChildren:
      () => import(
        './pages/data-tables/email-management/email-management.module'
        ).then(
        m => m.EmailManagementPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'events-management',
    loadChildren:
      () => import(
        './pages/data-tables/event-management/event-management.module'
        ).then(
        m => m.EventManagementModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'events-view',
    loadChildren:
      () => import(
        './pages/event-view-management/event-view-management.module'
        ).then(
        m => m.EventViewManagementPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'event-create',
    loadChildren:
      () => import(
        './pages/lakeshore-event-create/lakeshore-event-create.module'
        ).then(
        m => m.LakeshoreEventCreateModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'event-users/:eventId',
    loadChildren:
      () => import(
        './pages/registered-event-user-list/registered-event-user-list.module'
        ).then(
        m => m.RegisteredEventUserListPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'faq',
    loadChildren:
      () => import(
        './pages/faq/faq.module'
        ).then(
        m => m.FaqModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'lakeshore-events/:eventDayTimeStamp',
    loadChildren:
      () => import(
        './pages/lakeshore-events/lakeshore-events.module'
        ).then(
        m => m.LakeshoreEventsModule
      )
  },
  {
    path: 'lakeshore-events',
    loadChildren:
      () => import(
        './pages/lakeshore-events/lakeshore-events.module'
        ).then(
        m => m.LakeshoreEventsModule
      )
  },
  {
    path: 'lakeshore-weekly-events',
    loadChildren:
      () => import(
        './pages/lakeshore-events-weekly/lakeshore-events-weekly.module'
        ).then(
        m => m.LakeshoreEventsWeeklyPageModule
      )
  },
  {
    path: 'lakeshore-weekly-events/:weekIndex',
    loadChildren:
      () => import(
        './pages/lakeshore-events-weekly/lakeshore-events-weekly.module'
        ).then(
        m => m.LakeshoreEventsWeeklyPageModule
      )
  },
  {
    path: 'lakeshore-membership/:userId',
    loadChildren:
      () => import(
        './pages/lakeshore-membership-id/lakeshore-membership-id.module'
        ).then(
        m => m.LakeshoreMembershipIdModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'lakeshore-upcoming-classes-schedule',
    loadChildren:
      () => import(
        './pages/lakeshore-upcoming-user-events/lakeshore-upcoming-user-events.module'
        ).then(
        m => m.LakeshoreUpcomingUserEventsModule
      )
  },
  {
    path: 'pre-intervention-surveys',
    loadChildren:
      () => import(
        './pages/pre-intervention-surveys/pre-intervention-surveys.module'
        ).then(
        m => m.PreInterventionSurveysModule
      )
  },
  {
    path: 'event-edit/:lakeshoreEventId',
    loadChildren:
      () => import(
        './pages/lakeshore-event-edit/lakeshore-event-edit.module'
        ).then(
        m => m.LakeshoreEventEditModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'forgot-password',
    loadChildren:
      () => import(
        './pages/forgot-password/forgot-password.module'
        ).then(
        m => m.ForgotPasswordModule
      ),
    data: {
      username: ''
    },
  },
  {
    path: 'home',
    loadChildren:
      () => import(
        './pages/home/home.module'
        ).then(
        m => m.HomePageModule
      ),
    canActivate: [
      LoginGuard,
      IsSurveysCompleted
    ]
  },
  {
    path: 'instructor-video-list',
    loadChildren:
      () => import(
        './pages/instructor-video-list/instructor-video-list.module'
        ).then(
        m => m.InstructorVideoListPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'login',
    loadChildren:
      () => import(
        './pages/login/login.module'
        ).then(
        m => m.LoginPageModule
      ),
    data: {
      username: '',
      password: ''
    },
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'login-report',
    loadChildren:
      () => import(
        './pages/login-report/login-report.module'
        ).then(
        m => m.LoginReportPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'logout',
    loadChildren:
      () => import(
        './pages/logout/logout.module'
        ).then(
        m => m.LogoutPageModule
      ),
    data: {
      username: '',
      password: ''
    },
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'login-attempt-management',
    loadChildren:
      () => import(
        './pages/data-tables/login-attempt-management/login-attempt-management.module'
        ).then(
        m => m.LoginAttemptManagementPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'notes/:userId',
    loadChildren:
      () => import(
        './pages/notes/notes.module'
        ).then(
        m => m.NotesPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'not-found',
    loadChildren:
      () => import(
        './pages/not-found/not-found.module'
        ).then(
        m => m.NotFoundPageModule
      )
  },
  {
    path: 'notification-create',
    loadChildren:
      () => import(
        './pages/notification-create/notification-create.module'
        ).then(
        m => m.NotificationCreatePageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'notification-management',
    loadChildren:
      () => import(
        './pages/data-tables/notification-management/notification-management.module'
        ).then(
        m => m.NotificationManagementPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'notifications',
    loadChildren:
      () => import(
        './pages/notification-list/notification-list.module'
        ).then(
        m => m.NotificationListPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'playlist-archive-list',
    loadChildren:
      () => import(
        './pages/playlist-archive-list/playlist-archive-list.module'
        ).then(
        m => m.PlaylistArchiveListPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'playlist-archive-list-matching',
    loadChildren:
      () => import(
        './pages/playlist-archive-list-matching/playlist-archive-list-matching.module'
        ).then(
        m => m.PlaylistArchiveListMatchingPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'playlist-create',
    loadChildren:
      () => import(
        './pages/playlist-create/playlist-create.module'
        ).then(
        m => m.PlaylistCreatePageModule
      ),
    canActivate: [
      IsAtLeastLakeshoreAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'playlist-edit/:playlistId',
    loadChildren:
      () => import(
        './pages/playlist-edit/playlist-edit.module'
        ).then(
        m => m.PlaylistEditPageModule
      ),
    canActivate: [
      IsAtLeastLakeshoreAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'playlist-list',
    loadChildren:
      () => import(
        './pages/playlist-list/playlist-list.module'
        ).then(
        m => m.PlaylistListPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'playlist-list-matching',
    loadChildren:
      () => import(
        './pages/playlist-list-matching/playlist-list-matching.module'
        ).then(
        m => m.PlaylistListMatchingPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'playlist-list-week',
    loadChildren:
      () => import(
        './pages/playlist-list-week/playlist-list-week.module'
        ).then(
        m => m.PlaylistListWeekPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'playlist-management',
    loadChildren:
      () => import(
        './pages/data-tables/playlist-management/playlist-management.module'
        ).then(
        m => m.PlaylistManagementPageModule
      ),
    canActivate: [
      IsAtLeastLakeshoreAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'playlist-report',
    loadChildren:
      () => import(
        './pages/playlist-report/playlist-report.module'
        ).then(
        m => m.PlaylistReportPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'post-intervention-surveys',
    loadChildren:
      () => import(
        './pages/post-intervention-surveys/post-intervention-surveys.module'
        ).then(
        m => m.PostInterventionSurveysModule
      )
  },
  {
    path: 'post-class-feedback/:eventUserId',
    loadChildren:
      () => import(
        './pages/lakeshore-event-post-survey/lakeshore-event-post-survey.module'
        ).then(
        m => m.LakeshoreEventPostSurveyModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'register',
    loadChildren:
      () => import(
        './pages/register/register.module'
        ).then(
        m => m.RegisterPageModule
      )
  },
  {
    path: 'security-question-create',
    loadChildren:
      () => import(
        './pages/security-question-create/security-question-create.module'
        ).then(
        m => m.SecurityQuestionCreatePageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'security-question-edit/:securityQuestionId',
    loadChildren:
      () => import(
        './pages/security-question-edit/security-question-edit.module'
        ).then(
        m => m.SecurityQuestionEditPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'security-question-management',
    loadChildren:
      () => import(
        './pages/data-tables/security-question-management/security-question-management.module'
        ).then(
        m => m.SecurityQuestionManagementPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'set-password/:userId',
    loadChildren:
      () => import(
        './pages/set-password/set-password.module'
        ).then(
        m => m.SetPasswordPageModule
      ),
    canActivate: [
      LogoutGuard
    ]
  },
  {
    path: 'speed-check',
    loadChildren:
      () => import(
        './pages/speed-check/speed-check.module'
        ).then(
        m => m.SpeedCheckPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'sql-log-management',
    loadChildren:
      () => import(
        './pages/data-tables/sql-log-management/sql-log-management.module'
        ).then(
        m => m.SqlLogManagementPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'state-analytic-management',
    loadChildren:
      () => import(
        './pages/data-tables/state-analytic-management/state-analytic-management.module'
        ).then(
        m => m.StateAnalyticManagementPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'survey/:surveyId',
    loadChildren:
      () => import(
        './pages/survey/survey.module'
        ).then(
        m => m.SurveyPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'tag-create',
    loadChildren:
      () => import(
        './pages/tag-create/tag-create.module'
        ).then(
        m => m.TagCreatePageModule
      ),
    canActivate: [
      IsAtLeastLakeshoreAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'tag-edit/:tagId',
    loadChildren:
      () => import(
        './pages/tag-edit/tag-edit.module'
        ).then(
        m => m.TagEditPageModule
      ),
    canActivate: [
      IsAtLeastLakeshoreAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'tag-management',
    loadChildren:
      () => import(
        './pages/data-tables/tag-management/tag-management.module'
        ).then(
        m => m.TagManagementPageModule
      ),
    canActivate: [
      IsAtLeastLakeshoreAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'token-set-password/:token',
    loadChildren:
      () => import(
        './pages/token-set-password/token-set-password.module'
        ).then(
        m => m.TokenSetPasswordPageModule
      ),
    canActivate: [
      LogoutGuard
    ]
  },
  {
    path: 'user-analytic-management',
    loadChildren:
      () => import(
        './pages/data-tables/user-analytic-management/user-analytic-management.module'
        ).then(
        m => m.UserAnalyticManagementPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'un-activated-users',
    loadChildren:
      () => import(
        './pages/unactivated-users/unactivated-users.module'
        ).then(
        m => m.UnactivatedUsersPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'upcoming-sessions',
    loadChildren:
      () => import(
        './pages/precise-sessions/precise-sessions.module'
        ).then(
        m => m.PreciseSessionsModule
      ),
    canActivate: []
  },
  {
    path: 'user-create',
    loadChildren:
      () => import(
        './pages/user-create/user-create.module'
        ).then(
        m => m.UserCreatePageModule
      ),
    canActivate: [
      IsAtLeastLakeshoreAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'user-edit/:userId',
    loadChildren:
      () => import(
        './pages/user-edit/user-edit.module'
        ).then(
        m => m.UserEditPageModule
      ),
    canActivate: [
      IsAtLeastLakeshoreAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'user-timeline',
    loadChildren:
      () => import(
        './pages/user-timeline/user-timeline.module'
        ).then(
        m => m.UserTimelineModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'user-timeline/:userId',
    loadChildren:
      () => import(
        './pages/user-timeline/user-timeline.module'
        ).then(
        m => m.UserTimelineModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'user-management',
    loadChildren:
      () => import(
        './pages/data-tables/user-management/user-management.module'
        ).then(
        m => m.UserManagementPageModule
      ),
    canActivate: [
      IsAtLeastLakeshoreAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'user-detail',
    loadChildren:
      () => import(
        './pages/user-detail/user-detail.module'
        ).then(
        m => m.UserDetailPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'user-survey-answers/:surveyId/:userId',
    loadChildren:
      () => import(
        './pages/user-survey-answers/user-survey-answers.module'
        ).then(
        m => m.UserSurveyAnswersPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'user-survey-list/:userId',
    loadChildren:
      () => import(
        './pages/user-survey-list/user-survey-list.module'
        ).then(
        m => m.UserSurveyListPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'user-tags',
    loadChildren:
      () => import(
        './pages/user-tags/user-tags.module'
        ).then(
        m => m.UserTagsModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'user-type-create',
    loadChildren:
      () => import(
        './pages/user-type-create/user-type-create.module'
        ).then(
        m => m.UserTypeCreateModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'user-type-edit/:userTypeId',
    loadChildren:
      () => import(
        './pages/user-type-edit/user-type-edit.module'
        ).then(
        m => m.UserTypeEditPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'user-type-management',
    loadChildren:
      () => import(
        './pages/data-tables/user-type-management/user-type-management.module'
        ).then(
        m => m.UserTypeManagementPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'video/:videoId',
    loadChildren:
      () => import(
        './pages/video/video.module'
        ).then(
        m => m.VideoPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'playlist/:playlistId',
    loadChildren:
      () => import(
        './pages/video/video.module'
        ).then(
        m => m.VideoPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'playlist/:playlistId/:videoId',
    loadChildren:
      () => import(
        './pages/video/video.module'
        ).then(
        m => m.VideoPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'set-video-list',
    loadChildren:
      () => import(
        './pages/set-video-list/set-video-list.module'
        ).then(
        m => m.SetVideoListPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'version',
    loadChildren:
      () => import(
        './pages/version/version.module'
        ).then(
        m => m.VersionPageModule
      ),
  },
  {
    path: 'video-create',
    loadChildren:
      () => import(
        './pages/video-create/video-create.module'
        ).then(
        m => m.VideoCreatePageModule
      ),
    canActivate: [
      IsAtLeastLakeshoreAdminGuard
    ]
  },
  {
    path: 'video-edit/:videoId',
    loadChildren:
      () => import(
        './pages/video-edit/video-edit.module'
        ).then(
        m => m.VideoEditPageModule
      ),
    canActivate: [
      IsAtLeastLakeshoreAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'video-list',
    loadChildren:
      () => import(
        './pages/video-list/video-list.module'
        ).then(
        m => m.VideoListPageModule
      ),
    canActivate: [
      LoginGuard
    ],
  },
  {
    path: 'video-list-matching',
    loadChildren:
      () => import(
        './pages/video-list-matching/video-list-matching.module'
        ).then(
        m => m.VideoListMatchingPageModule
      ),
    canActivate: [
      LoginGuard
    ],
  },
  {
    path: 'video-list-week',
    loadChildren:
      () => import(
        './pages/video-list-week/video-list-week.module'
        ).then(
        m => m.VideoListWeekPageModule
      ),
    canActivate: [
      LoginGuard
    ],
  },
  {
    path: 'video-list-by-tags',
    loadChildren:
      () => import(
        './pages/video-list-by-tags/video-list-by-tags.module'
        ).then(
        m => m.VideoListByTagsPageModule
      ),
    canActivate: [
      LoginGuard
    ],
  },
  {
    path: 'video-management',
    loadChildren:
      () => import(
        './pages/data-tables/video-management/video-management.module'
        ).then(
        m => m.VideoManagementPageModule
      ),
    canActivate: [
      IsAtLeastLakeshoreAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'video-analytic-management',
    loadChildren:
      () => import(
        './pages/data-tables/video-analytic-management/video-analytic-management.module'
        ).then(
        m => m.VideoAnalyticManagementPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        enableTracing: false,
        useHash: true,
        relativeLinkResolution: 'legacy'
      }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
