import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';

import {
  ActiveUser,
  AlertService,
  LoginService,
  RedirectService,
} from '@uab.lakeshore.collaborative/next-base';

@Injectable()
export class IsSurveysCompleted implements CanActivate {
   constructor(
    private alert:AlertService,
    private login:LoginService,
    private redirectService:RedirectService
  ) {

  }

  canActivate():any {
    const activeUser = <ActiveUser><unknown>this.login.getActiveUser();
    if (activeUser) {
      if (activeUser.status.search('awaiting-survey-complete-') !== -1) {
        this.redirectService.goTo('pre-intervention-surveys').then();
      } else {
        return true;
      }
    }
  }
}
