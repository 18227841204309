import {BaseModel, User} from '@uab.lakeshore.collaborative/next-base';
import {TermsConditionsModel} from './terms-conditions.model';

export class TermsConditionsUsersModel extends BaseModel {
  public user_id:string = null;
  public users:User = null;
  public terms_and_condition_id:any = null;
  public terms_and_conditions?:TermsConditionsModel = null;

  constructor() {
    super();
  }
}
