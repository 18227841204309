import {Injectable} from '@angular/core';

import {
  BaseCrudService,
  EnvironmentService,
  ImageService,
  ListService,
  Note,
  RedirectService,
  Request,
  RequestService,
  Response,
  StringService,
  VariableService
} from '@uab.lakeshore.collaborative/next-base';
import {Observable} from 'rxjs';

@Injectable()
export class NoteService extends BaseCrudService {
  public model = 'notes';

  constructor(
    private image:ImageService,
    private variable:VariableService,
    private environment:EnvironmentService,
    private list:ListService,
    private redirect:RedirectService,
    private request:RequestService,
    private string:StringService
  ) {
    super(
      variable,
      environment,
      list,
      request,
      string
    );
  }

  create(notes:Note | Note[]) {
    return super._create(notes);
  }

  delete(notes:Note | Note[]) {
    return super._delete(notes);
  }

  getColumns(request?:Request):Observable<Response> {
    return super._getColumns(request);
  }

  getMore(request?:Request) {
    return super._getMore(request);
  }

  getPage(request?:Request) {
    return super._getPage(request);
  }
}
