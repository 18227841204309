import {Injectable} from '@angular/core';

import {Observable} from 'rxjs/Observable';

import {
  Article,
  BaseCrudService,
  EnvironmentService,
  ImageService,
  ListService,
  RedirectService,
  Request,
  RequestService,
  Response,
  StringService,
  VariableService
} from '@uab.lakeshore.collaborative/next-base';

@Injectable()
export class ArticleService extends BaseCrudService {
  public model = 'articles';

  constructor(
    private image:ImageService,
    private variable:VariableService,
    private environment:EnvironmentService,
    private list:ListService,
    private redirect:RedirectService,
    private request:RequestService,
    private string:StringService
  ) {
    super(
      variable,
      environment,
      list,
      request,
      string
    );
  }

  create(article:Article):Observable<Response> {
    return super._create(article);
  }

  delete(article:Article | Article[]):Observable<any> {
    return super._delete(article);
  }

  edit(article:Article):Observable<Response> {
    return super._edit(article);
  }

  getColumns(request?:Request):Observable<Response> {
    return super._getColumns(request);
  }

  getImage(article:Article):string {
    const filePath = this.variable.getString(
      article.content_images.file_path
    );

    if (this.image.isBase64(filePath)) {
      return filePath;
    } else {
      const backendUrl = this.variable.getString(
        this.environment.get('backendUrl')
      );

      const prefix = filePath.substring(0, 1);

      const suffix = backendUrl.substring(backendUrl.length - 1);

      let glue = '';
      if (prefix !== '/' && suffix !== '/') {
        glue = '/';
      }

      return backendUrl + glue + filePath;
    }
  }

  getMore(request?:Request):Observable<Response> {
    return super._getMore(request);
  }

  getPage(request?:Request):Observable<Response> {
    return super._getPage(request);
  }

  load(request?:Request):Observable<Response> {
    return super._load(request);
  }
}
