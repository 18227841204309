import {Component, Input} from '@angular/core';

import {
  ActiveUser,
  BaseComponent,
  ImageService,
  LoginService,
  RedirectService,
  User
} from '@uab.lakeshore.collaborative/next-base';

@Component({
  selector: 'uab-user-name-card',
  templateUrl: 'user-name-card.component.html',
  styleUrls: [
    'user-name-card.component.scss'
  ]
})
export class UserNameCardComponent implements BaseComponent {
  @Input() sref:string;

  public isLoadingUser = true;
  public isLoggedIn = true;
  public user:User = new User();

  constructor(
    private image:ImageService,
    private login:LoginService,
    private redirect:RedirectService
  ) {
    this.reset();

    this.loadUser();

    this.login.registerUserUpdated(
      (activeUser) => {
        if (activeUser) {
          this.user = activeUser;
        }
      }
    );

    this.isLoggedIn = this.login.isLoggedIn();

    this.login.registerLogin(
      (loggedIn) => {
        this.isLoggedIn = loggedIn;

        this.loadUser();
      }
    );

    this.login.registerLogout(
      (loggedIn) => {
        this.isLoggedIn = loggedIn;
      }
    );
  }

  getUserImage(user:User):string {
    return this.image.getImageSource(user, 'users', {colorize: false});
  }

  goToUser():void {
    this.redirect.goTo(
      this.sref,
      {
        userId: this.user.id
      }
    );
  }

  loadUser():void {
    this.isLoadingUser = true;

    const activeUser = this.login.getActiveUser();
    if (activeUser) {
      this.user = <ActiveUser><unknown>activeUser;
    }

    this.isLoadingUser = false;
  }

  reset():void {
    this.isLoadingUser = false;
  }
}
