import {BaseModel} from '@uab.lakeshore.collaborative/next-base';

export class HardcodedQuestionModel extends BaseModel {
  public phrasing:string = null;
  public answer_options:any[] = [];
  public answer:any = null;
  public modelName:string = null;

  constructor() {
    super();
  }
}
