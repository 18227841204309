import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'uab-footer',
  templateUrl: 'footer.component.html',
  styleUrls: [
    'footer.component.scss'
  ]
})
export class FooterComponent implements OnInit {
  constructor() {
    this.reset();
  }

  ngOnInit():void {
  }

  reset():void {
  }

}
