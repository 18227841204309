<ion-card *ngIf="isLoading"
          class="terms-and-conditions-skeleton">
  <div *ngFor="let range of [] | range:3">
    <uab-skeleton-paragraph></uab-skeleton-paragraph>

    <ion-item-divider></ion-item-divider>
  </div>
</ion-card>

<ion-card *ngIf="!isLoading"
          class="optIntoStudy">
  <div *ngFor="let tc of termsAndConditions"
       class="ion-margin">
    <ion-text>
      <div [innerHTML]="tc.terms | safeHtml" class="lakeshore_paragraph_style"></div>
    </ion-text>
  </div>

  <div class="text-center" style="margin: 5px; color: red; font-size: 25px; font-weight: bold"
       *ngIf="(disableNextButton && showDisableText)">
    Please watch the video and then select your response.
  </div>

  <ion-item-divider></ion-item-divider>
  <uab-button-group (acceptOnClick)="onAccept.emit()"
                    (declineOnClick)="onDecline.emit()"
                    [acceptActiveText]="acceptingButtonText"
                    [acceptActive]="isAcceptingTerms"
                    [acceptDisabledTooltip]="acceptButtonTooltip"
                    [declineDisabledTooltip]="declineButtonTooltip"
                    [acceptButtonId]="'optInto-accept-button'"
                    [acceptDisabled]="disableNextButton"
                    [acceptText]="acceptButtonText"
                    [declineActiveText]="decliningButtonText"
                    [declineDisabled]="disableNextButton"
                    [declineText]="declineButtonText"
                    [showDeclineButton]="showDeclineButton"
                    [swapButtonOrder]="true">
  </uab-button-group>

</ion-card>
