<ion-list [lines]="'full'"
          class="secondary-color"
          style="
                  height: 96%;
                  margin-top: -10px;
                  overflow-y: scroll;
                ">
  <uab-user-name-card [sref]="'profile'"></uab-user-name-card>

  <div *ngIf="!isLoadingSections">
    <div *ngFor="let featureUserType of featureUserTypes; let i = index"
         [ngStyle]="{ 'animation-delay': 10*i + 'ms' }"
         class="animated fadeIn">
      <div *ngIf="featureUserType.sref === ''"
           [ngClass]=" {
              'menu-feature-divider primary-color': true
           }">
        <ion-label>
          {{ featureUserType.name ? featureUserType.name : featureUserType.features.name }}
        </ion-label>
      </div>

      <uab-menu-feature-button (onClick)="closeMenu()"
                               *ngIf="featureUserType.sref !== ''"
                               [featureUserType]="featureUserType"></uab-menu-feature-button>
    </div>

    <uab-menu-feature-button *ngIf="showSetVideoFeature"
                             [enableRoutes]="true"
                             [featureUserType]="setVideosFeature"></uab-menu-feature-button>

    <uab-menu-feature-button (click)="onLogout()"
                             (onClick)="closeMenu()"
                             [enableRoutes]="false"
                             [featureUserType]="logoutFeature"></uab-menu-feature-button>

    <div *ngIf="version.length !== 0"
         class="speed secondary-color ion-text-center">
      Internet Speed: {{ speedCategorty }}
    </div>

    <div *ngIf="version.length !== 0"
         class="menu-version secondary-color ion-text-center">
      Version: {{ version }}
    </div>

    <div *ngIf="isLoadingSections"
         class="menu-version">
      <uab-skeleton-block *ngFor="let range of [] | range:skeletonRange"></uab-skeleton-block>
    </div>

    <div *ngIf="isLoadingSections">
      <ion-spinner class="center"></ion-spinner>
    </div>
  </div>
</ion-list>
<uab-footer></uab-footer>
