<ion-card class="post-create">
  <div *ngIf="isCreatingUserQuestionAnswer">
    <div style="min-height: 125px; width: 100%; margin: auto; display: inline-flex">
        <span style="margin: auto">
           <ion-spinner name="circles"></ion-spinner>
        </span>
    </div>
  </div>

  <ion-card-header>
    <h2 class="text-center" style="font-weight: 600">
      {{surveyName}}
    </h2>
  </ion-card-header>

  <ion-card-content>
    <div *ngFor="let question of hardcodedQuestions">
      <ion-row>
        <ion-col [size]="12">
          <h1 style="size: 15px">{{ question.phrasing }}</h1>
        </ion-col>

        <ion-col [size]="12">
          <ion-radio-group [(ngModel)]="question.answer">
            <ion-item *ngFor="let option of question.answer_options">
              <ion-label style="white-space: normal">
                {{ option }}
              </ion-label>

              <ion-radio [id]="'question-' + question.id + '-radio-option-' + option"
                         [name]="'question-' + question.id + '-radio-option-' + option"
                         [slot]="'start'"
                         [value]="option"></ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-col>
      </ion-row>
    </div>
  </ion-card-content>

  <uab-button-group (acceptOnClick)="createScreeningUserAnswers()"
                    (declineOnClick)="cancel()"
                    [acceptActiveText]="'Submitting'"
                    [acceptActive]="isCreatingUserQuestionAnswer"
                    [acceptButtonId]="'answers-create-button'"
                    [acceptDisabled]="isCreatingUserQuestionAnswer"
                    [acceptText]="'Submit'"
                    [declineDisabled]="isCreatingUserQuestionAnswer"
                    [declineText]="'Quit'"
                    [swapButtonOrder]="true">
  </uab-button-group>
</ion-card>

