import {Injectable} from '@angular/core';
import {ActivatedRoute, CanActivate} from '@angular/router';

import {ActiveUser, AlertService, LoginService, User, UserTypes} from '@uab.lakeshore.collaborative/next-base';

@Injectable()
export class IsAtLeastAdminGuard implements CanActivate {
   constructor(
    private alert:AlertService,
    private login:LoginService,
    private route:ActivatedRoute
  ) {

  }

  canActivate():boolean {
    let isAtLeastAdmin = false;

    const roles = this.getRoles();

    const activeUser = <ActiveUser><unknown>this.login.getActiveUser();
    if (activeUser) {
      isAtLeastAdmin = roles.includes((<User>activeUser).user_type_name);
    }

    if (isAtLeastAdmin === false) {
      this.alert.addError('Only admins can access this page.');
    }

    return isAtLeastAdmin;
  }

  getRoles() {
     return [
       UserTypes.SUPER_ADMIN, UserTypes.ADMIN, 'research_admin'
     ];
  }
}
