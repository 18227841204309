import {Component, EventEmitter, Input, Output} from '@angular/core';

import {
  ArrayService,
  BaseComponent,
  EnvironmentService,
  FeatureUserType,
  LingoService,
  LoginService,
  PlatformService,
  RedirectService,
  RequestService,
  RouteService,
  SplashScreenService,
  VariableService
} from '@uab.lakeshore.collaborative/next-base';
import {finalize} from 'rxjs/operators';
import {FeatureUserTypeService} from '../../services/feature-user-type.service';
import {UserDurationService} from '../../services/user-duration.service';
import {MenuController} from '@ionic/angular';
import {ConfirmationController} from '@uab.lakeshore.collaborative/next-base';
import {SpeedCheckinsService} from '../../services/speed-checkins.service';
import {ActiveUserModel} from '../../models/active-user.model';

@Component({
  selector: 'uab-menu-list',
  templateUrl: 'menu-list.component.html',
  styleUrls: [
    'menu-list.component.scss'
  ],
})
export class MenuListComponent implements BaseComponent {
  @Input() skeletonRange:number = 8;

  @Output() onComplete:EventEmitter<true>;

  public activeUser:ActiveUserModel;
  public currentWeek:number;
  public featureUserTypes:FeatureUserType[];
  public mode:string;
  public isLoadingActiveUser:boolean;
  public showSetVideoFeature:boolean = false;
  public isLoadingSections:boolean;
  public isLoggedIn:boolean;
  public isBlocklistMenu:boolean;
  public logoutFeature:FeatureUserType;
  public setVideosFeature:FeatureUserType;
  public goToStudyAgain:FeatureUserType;
  public version:string;
  public speed:number = null;
  public speedCategorty:string;

  constructor(
    private array:ArrayService,
    private confirm:ConfirmationController,
    private durationService:UserDurationService,
    private environment:EnvironmentService,
    private featureUserTypeService:FeatureUserTypeService,
    private lingo:LingoService,
    private login:LoginService,
    private menu:MenuController,
    private platform:PlatformService,
    private redirect:RedirectService,
    private route:RouteService,
    private requestService:RequestService,
    private splashService:SplashScreenService,
    private speedCheckinsService:SpeedCheckinsService,
    private variable:VariableService
  ) {
    this.onComplete = new EventEmitter<true>();

    this.reset();

    if (this.login.isLoggedIn()) {
      this.loadActiveUser();
    }

    this.login.registerLogin(
      () => {
        this.loadActiveUser();
      }
    );

    this.login.registerLogout(
      () => {
        this.reset();
      }
    );
  }

  closeMenu():void {
    this.menu.close().then();
  }

  loadSpeed() {
    let request = this.requestService.getRequest();

    request = this.requestService.setPrefix(request, 'api');
    request = this.requestService.setAction(request, 'get');
    request = this.requestService.setOrder(request, '-created');
    request = this.requestService.setWheres(request, ['user_id=' + this.activeUser.id]);

    return this.speedCheckinsService.getMore(request).pipe(
      finalize(
        () => {
        }
      )
    ).subscribe(
      (response) => {
        if (response.data !== null) {
          this.speed = response.data[0].speed;

          if (this.speed > 0 && this.speed < 30) {
            this.speedCategorty = 'Low';
          } else if (this.speed > 30 && this.speed < 100) {
            this.speedCategorty = 'Moderate';
          } else if (this.speed > 100) {
            this.speedCategorty = 'High';
          }
          console.log(this.speed);
        }
      }
    );
  }

  loadActiveUser():void {
    this.isLoadingActiveUser = true;

    this.splashService.show('menu-list-component-load-active-user');

    const activeUser = this.login.getActiveUser();
    if (activeUser) {
      this.activeUser = <ActiveUserModel><unknown>activeUser;

      if (this.activeUser.video_start_time !== null) {
        this.showSetVideoFeature = true;
      }

      this.currentWeek = this.durationService.getWeeks(this.activeUser);
      this.loadSections();

      this.isLoggedIn = true;
    } else {
      this.onComplete.emit(true);

      this.isLoggedIn = false;
    }

    this.isLoadingActiveUser = false;

    this.splashService.hide('menu-list-component-load-active-user');
  }

  loadSections():void {
    this.isLoadingSections = true;

    this.splashService.show('menu-list-component-load-sections');

    let request = this.requestService.getRequest();

    request = this.requestService.setJoins(
      request,
      [
        '>features'
      ]
    );

    request = this.requestService.setLimit(
      request,
      100
    );

    request = this.requestService.setWheres(
      request,
      [
        'user_type_id=' + (<ActiveUserModel>this.activeUser).user_type_id
      ]
    );

    request = this.requestService.setOrder(request, 'position');

    request = this.requestService.setUseCache(request, true);

    this.featureUserTypeService.getMore(request).pipe(
      finalize(
        () => {
          this.isLoadingSections = false;

          this.splashService.hide('menu-list-component-load-sections');
        }
      )
    ).subscribe(
      (response) => {
        const featureUserTypes = this.variable.getArray(
          response.data
        );
        this.loadSpeed();

        this.setFeatureUserTypes(featureUserTypes);
      }
    );
  }

  onLogout():void {
    this.confirm.confirm('Are you sure you want to logout?').then(
      (confirm) => {
        if (confirm) {
          this.login.logout().subscribe();
        }
      }
    );
  }

  reset():void {
    this.activeUser = new ActiveUserModel();

    this.isLoadingActiveUser = true;

    this.isLoadingSections = true;

    this.isBlocklistMenu = false;

    this.logoutFeature = new FeatureUserType();
    this.logoutFeature.name = 'Logout';
    this.logoutFeature.sref = null;
    this.logoutFeature.icon = 'logout';

    this.setVideosFeature = new FeatureUserType();
    this.setVideosFeature.name = 'Supplementary Videos';
    this.setVideosFeature.sref = 'set-video-list';
    this.setVideosFeature.icon = 'playlist-list';

    this.version = this.environment.getString('version', '');
  }

  setFeatureUserTypes(featureUserTypes:FeatureUserType[]):void {
    const length = featureUserTypes.length;

    for (let i = 0; i < length; i++) {
      const featureUserType = featureUserTypes[i];

      if (!this.variable.isDefined(featureUserType.icon)) {
        const parent = this.variable.getObject(
          featureUserType.features
        );

        if (this.variable.isDefined(parent) && this.variable.isDefined(parent.icon)) {
          featureUserType.icon = parent.icon;
        }
      }

      if (!this.variable.isDefined(featureUserType.name)) {
        const parent = this.variable.getObject(
          featureUserType.features
        );

        featureUserType.name = parent.name;
      }

      if (!this.variable.isDefined(featureUserType.sref)) {
        const parent = this.variable.getObject(
          featureUserType.features
        );

        featureUserType.sref = parent.sref;
      }
    }

    this.featureUserTypes = featureUserTypes.filter(
      (featureUserType:FeatureUserType) => {
        if (featureUserType.start_week === null || featureUserType.start_week <= this.currentWeek) {
          if (featureUserType.end_week === null || featureUserType.end_week >= this.currentWeek) {
            return true;
          }
        }

        return false;
      }
    );

    setTimeout(
      () => {
        this.onComplete.emit(true);
      },
      100
    );

    // TODO: Jeremy
    // this.featureUserTypeService.addRoutes(this.activeUser).subscribe(
    //   () => {
    //     setTimeout(
    //       () => {
    //         this.onComplete.emit(true);
    //       },
    //       100
    //     );
    //   }
    // );
  }
}
