import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular';
import SentryRRWeb from '@sentry/rrweb';
import {Integrations} from '@sentry/tracing';

import * as moment from 'moment';

import {AppModule} from './app/app.module';

import {environment} from './environments/environment';

const ignoreErrors = [
  'top.GLOBALS',
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  'Can\'t find variable: ZiteReader',
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  'fb_xd_fragment',
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  'conduitPage',
  /ChunkLoadError/i,
  /Cannot read property 'isProxied' of undefined/i,
  /evaluating 'o.isProxied'/i,
  /User not allowed to login/i,
  /Notification is not defined/i,
  /Can't find variable: Notification/i,
];

Sentry.init({
  environment: environment.environment,
  release: environment.project + '@' + environment.version,
  dsn: 'https://9a87f41252914405b9f8d5a990bc4bfa@o412548.ingest.sentry.io/5545274',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [environment.frontendUrl],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new SentryRRWeb()
  ],
  tracesSampleRate: 1.0,
  ignoreErrors: ignoreErrors,
  denyUrls: [
    /graph\.facebook\.com/i,
    /connect\.facebook\.net\/en_US\/all\.js/i,
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    /extensions\//i,
    /^chrome:\/\//i,
    /127\.0\.0\.1:4001\/isrunning/i,
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    /localhost/i,
  ],
  beforeSend(event, hint) {
    // Block local
    if (environment.environment === 'local') {
      return null;
    }

    // Filter errors
    const errorLength = ignoreErrors.length;
    for (let i = 0; i < errorLength; i++) {
      if (event.message.match(ignoreErrors[i])) {
        return null;
      }
    }

    // Rate limit
    let errorLog:any = localStorage.getItem('errorLog');
    if (errorLog === null) {
      errorLog = {};
    } else {
      errorLog = JSON.parse(errorLog);
    }

    const errorKey = 'error-' + event.fingerprint;
    if (typeof errorLog[errorKey] === 'undefined') {
      errorLog[errorKey] = {
        count: 0,
        last: new Date()
      };
    }

    if (moment(errorLog[errorKey].last).isBefore(moment(30, 'm').add())) {
      errorLog[errorKey].count = 0;
    }

    errorLog[errorKey].last = new Date();
    errorLog[errorKey].count++;

    localStorage.setItem('errorLog', JSON.stringify(errorLog));

    if (errorLog[errorKey].count > 10) {
      return;
    }

    return event;
  }
});

if (environment.environment === 'prod') {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).then(
  (ref) => {
    if (window['ngRef']) {
      window['ngRef'].destroy();
    }

    window['ngRef'] = ref;
  }
).catch(
  (err) => {
    console.log(err);
  }
);
