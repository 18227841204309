import {Injectable} from '@angular/core';

import {
  BaseCrudService,
  DateService,
  EnvironmentService,
  HttpService,
  ListService,
  LoginService,
  Request,
  RequestService,
  Response,
  SecurityQuestion,
  StringService,
  VariableService
} from '@uab.lakeshore.collaborative/next-base';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class SecurityQuestionService extends BaseCrudService {
  public model = 'security_questions';

  constructor(
    private dateService:DateService,
    private environment:EnvironmentService,
    private variable:VariableService,
    private http:HttpService,
    private list:ListService,
    private login:LoginService,
    private request:RequestService,
    private string:StringService
  ) {
    super(
      variable,
      environment,
      list,
      request,
      string
    );
  }

  createQuestion(question):Observable<Response> {
    return super._create(question);
  }

  delete(question:SecurityQuestion | SecurityQuestion[]):Observable<Response> {
    return super._delete(question);
  }

  editQuestion(question):Observable<Response> {
    return super._edit(question);
  }

  getColumns(request?:Request):Observable<Response> {
    return super._getColumns(request);
  }

  getQuestion(request?:Request):Observable<Response> {
    return super._load(request);
  }

  getMore(request?:Request):Observable<Response> {
    return super._getMore(request);
  }

  getPage(request?:Request):Observable<Response> {
    return super._getPage(request);
  }
}
