import {Injectable} from '@angular/core';

import {
  BaseCrudService,
  EnvironmentService,
  HttpService,
  ListService,
  Request,
  RequestService,
  Response,
  StringService,
  Tag,
  VariableService
} from '@uab.lakeshore.collaborative/next-base';
import {Observable} from 'rxjs';

@Injectable()
export class TagService extends BaseCrudService {
  public model = 'tags';

  constructor(
    private variable:VariableService,
    private environment:EnvironmentService,
    private http:HttpService,
    private list:ListService,
    private request:RequestService,
    private string:StringService
  ) {
    super(
      variable,
      environment,
      list,
      request,
      string
    );
  }

  createTag(newTag:Tag):Observable<Response> {
    return super._create(newTag);
  }

  delete(tag:Tag | Tag[]):Observable<Response> {
    return super._delete(tag);
  }

  editTag(tag):Observable<Response> {
    return super._edit(tag);
  }

  getColumns(request?:Request):Observable<Response> {
    return super._getColumns(request);
  }

  getTag(request?:Request):Observable<Response> {
    return super._load(request);
  }

  getTags(request?) {
    return this.loadAllTags(request);
  }

  getMore(request?:Request):Observable<Response> {
    return super._getMore(request);
  }

  getPage(request) {
    return this.list.getPage(request);
  }

  loadAllTags(request) {
    request = this.request.setLimit(request, 1000);

    return this.list.get(request);
  }

  getUserTags(id:string, request?:Request) {
    request = this.request.getRequest(request);

    request = this.request.setModel(request, 'tags');
    request = this.request.setAction(request, 'users');
    request = this.request.setSubAction(request, id);

    request = this.request.setLimit(request, 1000);

    return this.list.get(request);
  }
}
