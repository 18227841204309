export const environment = {
  environment: 'prod',
  frontendUrl: 'https://fitness.lakeshore.org',
  backendUrl: 'https://fitness.lakeshore.org/laravel',
  socketUrl: 'https://app.scipe.org:3000',
  production: true,
  project: 'precise',
  sentryRateLimitCount: 3,
  sentryRateLimitSeconds: 3,
  splashScreen: 'folding-cube',
  slider_pin: true,
  slider_ticks: true,
  slider_snap: true,
  toastDuration: 8000,
  version: '0.0.1',
  weeks: '8',
  allowedSpecialCharacters: '!@#$%^&*()-_<>?',
  minPasswordLength: 5,
  minSpecialLetterLength: 0,
  debug: true,
  mode: 'default',
  defaultArticleImage: 'assets/defaults/default_article.png',
  defaultBadgeImage: 'assets/defaults/default_badge.png',
  defaultFemaleImage: 'assets/icons/profile_icon.png',
  defaultMaleImage: 'assets/icons/profile_icon.png',
  defaultNotificationImage: 'assets/defaults/default_notification.png',
  defaultPlaylistImage: 'assets/defaults/default_playlist.png',
  defaultSurveyImage: 'assets/defaults/default_survey.png',
  defaultVideoImage: 'assets/defaults/default_video.png',
  enableNotifications: false,
  minimumInternetSpeed: 5,
  requiredReadTime: 1,
  similarMinimum: 50,
  speedCheckLimit: 10,
  updateActiveUserAfter: 120000,
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
import 'zone.js/plugins/zone-error';
