import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {RequestService, TermsAndCondition, User, UserInterface} from '@uab.lakeshore.collaborative/next-base';
import {finalize} from 'rxjs/operators';
import {RegisterService} from '../../services/register.service';

@Component({
  selector: 'uab-optin-optout',
  templateUrl: 'optin-optout.component.html',
  styleUrls: [
    'optin-optout.component.scss'
  ]
})
export class OptinOptoutComponent {
  public acceptTermsForm:FormGroup;
  public isAcceptingTerms:boolean;
  public isLoading:boolean;
  public termsAndConditions:TermsAndCondition[];
  public applicant:User;
  public termsId:string;
  public buttonsDisabled:boolean = true;

  @Input() acceptButtonText:string = 'Accept';
  @Input() acceptButtonTooltip:string = '';
  @Input() declineButtonTooltip:string = '';
  @Input() declineButtonText:string = 'Decline';
  @Input() acceptingButtonText:string = 'Accepting...';
  @Input() decliningButtonText:string = 'Declining...';
  @Input() showDeclineButton:boolean = true;
  @Input() disableNextButton:boolean = false;
  @Input() showDisableText:boolean = false;
  @Output() onAccept:EventEmitter<UserInterface>;
  @Output() onDecline:EventEmitter<UserInterface>;

  constructor(
    private registerService:RegisterService,
    private requestService:RequestService,
  ) {
    this.onAccept = new EventEmitter<UserInterface>();
    this.onDecline = new EventEmitter<UserInterface>();

    this.reset();
  }

  @Input() set termsConditionsId(id:string) {
    if (id !== this.termsId) {
      this.termsId = id;
      this.loadTermsAndCondition();
    }
  }

  isDisabled():boolean {
    return !this.acceptTermsForm.valid;
  }

  loadTermsAndCondition():void {
    this.isLoading = true;

    let request = this.requestService.getRequest();
    request = this.requestService.setId(request, this.termsId);

    this.registerService.getTermsAndConditions(request).pipe(
      finalize(
        () => {
          this.isLoading = false;
        }
      )
    ).subscribe(
      (response) => {
        if (!response.errors) {
          this.termsAndConditions = <TermsAndCondition[]>response.data;
        }
      }
    );
  }

  activateDisabledAcceptButton() {
    this.buttonsDisabled = false;
  }

  reset():void {
    this.applicant = new User();

    this.isAcceptingTerms = false;

    this.isLoading = false;
  }
}
