import {Injectable} from '@angular/core';

import {Observable} from 'rxjs/Observable';

import {
  BaseCrudService,
  EnvironmentService,
  ListService,
  Request,
  RequestService,
  Response,
  StringService,
  VariableService
} from '@uab.lakeshore.collaborative/next-base';
import {SpeedCheckinsModel} from '../models/speed-checkins.model';

@Injectable()
export class SpeedCheckinsService extends BaseCrudService {
  public model = 'speed_checkins';

  constructor(
    private variable:VariableService,
    private environment:EnvironmentService,
    private list:ListService,
    private request:RequestService,
    private string:StringService
  ) {
    super(
      variable,
      environment,
      list,
      request,
      string
    );
  }

  create(checkin:SpeedCheckinsModel):Observable<Response> {
    return super._create(checkin);
  }

  getColumns(request?:Request):Observable<Response> {
    return super._getColumns(request);
  }

  getMore(request?:Request):Observable<Response> {
    return super._getMore(request);
  }

  getPage(request?:Request):Observable<Response> {
    return super._getPage(request);
  }

  load(request?:Request):Observable<Response> {
    return super._load(request);
  }
}
