import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {RequestService, TermsAndCondition, User, UserInterface} from '@uab.lakeshore.collaborative/next-base';
import {finalize} from 'rxjs/operators';
import {RegisterService} from '../../services/register.service';

@Component({
  selector: 'uab-accept-terms',
  templateUrl: 'accept-terms.component.html',
  styleUrls: [
    'accept-terms.component.scss'
  ]
})
export class AcceptTermsComponent {
  public acceptTermsForm:FormGroup;
  public isAcceptingTerms:boolean;
  public isLoading:boolean;
  public termsAndConditions:TermsAndCondition[];
  public applicant:User;

  @Input() acceptButtonText:string = 'Accept';
  @Input() acceptingButtonText:string = 'Accepting...';

  @Output() onAccept:EventEmitter<UserInterface>;

  constructor(
    private registerService:RegisterService,
    private requestService:RequestService,
  ) {
    this.onAccept = new EventEmitter<UserInterface>();

    this.reset();
    this.loadTermsAndCondition();
  }

  isDisabled():boolean {
    return !this.acceptTermsForm.valid;
  }

  loadTermsAndCondition():void {
    this.isLoading = true;

    let request = this.requestService.getRequest();
    request = this.requestService.setId(request, '8');

    this.registerService.getTermsAndConditions(request).pipe(
      finalize(
        () => {
          this.isLoading = false;
        }
      )
    ).subscribe(
      (response) => {
        if (!response.errors) {
          this.termsAndConditions = <TermsAndCondition[]>response.data;
        }
      }
    );
  }

  reset():void {
    this.applicant = new User();

    this.isAcceptingTerms = false;

    this.isLoading = false;
  }
}
