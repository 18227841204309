<ion-footer>
<!--    <div style="width: 100%; height: 80%;" class="primary-color">
      <div style="height: 80%; width: 10%; max-height: 100px !important; margin: 0 auto; padding-top: 5px">
        <img src="assets/branding/rectech.png" alt="Logo" style='height: 80%; width: 80%; object-fit: contain; max-width: 80px'>
      </div>
      <div class="footer-text">
        Powered by RecTech
      </div>
    </div>-->
  <div style="display: flex; justify-content: center; padding-right: 10px">
    <div style="padding-top: 5px">
      <img src="assets/branding/rectech.png" alt="Logo" style='max-width: 200px'>
    </div>
  </div>
</ion-footer>

