import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';

import {ButtonGroupModule, TextAreaModule} from '@uab.lakeshore.collaborative/next-forms';

import {NoteService} from '../../services/note.service';

import {ConfirmationModule, ListService, RedirectService, SafeHtmlModule} from '@uab.lakeshore.collaborative/next-base';

import {ScreeningQuestionsComponent} from './screening-questions.component';

@NgModule({
  declarations: [
    ScreeningQuestionsComponent
  ],
  exports: [
    ScreeningQuestionsComponent
  ],
  imports: [
    ButtonGroupModule,
    CommonModule,
    FormsModule,
    IonicModule,
    ConfirmationModule,
    SafeHtmlModule,
    TextAreaModule,
  ],
  providers: [
    NoteService,
    ListService,
    RedirectService
  ]
})
export class ScreeningQuestionsModule {
}
