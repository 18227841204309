import {Injectable} from '@angular/core';

import {IsAtLeastAdminGuard} from './is-at-least-admin.guard';
import {UserTypes} from '@uab.lakeshore.collaborative/next-base';

@Injectable()
export class IsAtLeastLakeshoreAdminGuard extends IsAtLeastAdminGuard {
  getRoles() {
    return [
      UserTypes.SUPER_ADMIN, UserTypes.ADMIN, 'research_admin', 'lakeshore_admin'
    ];
  }
}
