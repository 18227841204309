import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';

import {CompleteProfileModalComponent} from './complete-profile-modal.component';

import {SurveyFormModule, SurveyModule} from '@uab.lakeshore.collaborative/next-surveys';
import {AcceptTermsModule} from '../accept-terms/accept-terms.module';

import {ConfirmationModule, SegmentHeaderModule} from '@uab.lakeshore.collaborative/next-base';

import {SpeedCheckModule} from '@uab.lakeshore.collaborative/next-tech';
import {OptinOptoutModule} from '../optin-optout/optin-optout.module';
import {TermsConditionsUsersService} from '../../services/terms-conditions-users.service';
import {ScreeningQuestionsModule} from '../screening-questions/screening-questions.module';
import {ButtonGroupModule, TimeModule} from '@uab.lakeshore.collaborative/next-forms';
import {SharedSubscriptionService} from '../../services/sharedSubscription.service';
import {YouTubePlayerModule} from '@angular/youtube-player';

@NgModule({
  declarations: [
    CompleteProfileModalComponent
  ],
  entryComponents: [
    CompleteProfileModalComponent
  ],
  exports: [
    CompleteProfileModalComponent
  ],
    imports: [
        AcceptTermsModule,
        OptinOptoutModule,
        CommonModule,
        ConfirmationModule,
        FormsModule,
        IonicModule,
        SurveyFormModule,
        SegmentHeaderModule,
        SpeedCheckModule,
        SurveyModule,
        ScreeningQuestionsModule,
        TimeModule,
        ButtonGroupModule,
        YouTubePlayerModule,
    ],
  providers: [
    TermsConditionsUsersService,
    SharedSubscriptionService
  ]
})
export class CompleteProfileModalModule {
}
