import {Injectable} from '@angular/core';

import {
  BaseCrudService,
  EnvironmentService,
  ImageService,
  ListService,
  RedirectService,
  Request,
  RequestService,
  Response,
  StringService,
  VariableService
} from '@uab.lakeshore.collaborative/next-base';
import {Observable} from 'rxjs';
import {TermsConditionsUsersModel} from '../models/terms-conditions-users.model';

@Injectable()
export class TermsConditionsUsersService extends BaseCrudService {
  public model = 'terms_and_conditions_users';

  constructor(
    private image:ImageService,
    private variable:VariableService,
    private environment:EnvironmentService,
    private list:ListService,
    private redirect:RedirectService,
    private request:RequestService,
    private string:StringService
  ) {
    super(
      variable,
      environment,
      list,
      request,
      string
    );
  }

  create(termsConditionsUser:TermsConditionsUsersModel | TermsConditionsUsersModel[]) {
    return super._create(termsConditionsUser);
  }

  delete(termsConditionsUser:TermsConditionsUsersModel | TermsConditionsUsersModel[]) {
    return super._delete(termsConditionsUser);
  }

  getColumns(request?:Request):Observable<Response> {
    return super._getColumns(request);
  }

  getMore(request?:Request) {
    return super._getMore(request);
  }

  getPage(request?:Request) {
    return super._getPage(request);
  }

  createTermsandConditionsUserToOptintoStudy(termsConditionsUser:TermsConditionsUsersModel) {
    termsConditionsUser.terms_and_condition_id = '11';

    return this.create(termsConditionsUser);
  }
}
