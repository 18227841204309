import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';

import {RangeModule, SafeHtmlModule, SkeletonParagraphModule} from '@uab.lakeshore.collaborative/next-base';

import {AcceptTermsComponent} from './accept-terms.component';
import {ButtonGroupModule} from '@uab.lakeshore.collaborative/next-forms';

@NgModule({
  exports: [
    AcceptTermsComponent
  ],
  imports: [
    ButtonGroupModule,
    CommonModule,
    FormsModule,
    IonicModule,
    SkeletonParagraphModule,
    RangeModule,
    ReactiveFormsModule,
    SafeHtmlModule
  ],
  declarations: [
    AcceptTermsComponent
  ],
})
export class AcceptTermsModule {
}
