<ion-card *ngIf="showModal"
          id="complete-profile-modal"
          class="animated bounceIn"
          style="background-color: white; height: 98%; overflow: scroll;">
  <div *ngIf="selectedSegment !== ('awaiting-terms-conditions' && 'awaiting-admin-approval' )" class="complete-profile-exit show-hand-cursor"
       (click)="logout()">
    <ion-icon [src]="'assets/icons/cancel.svg'"></ion-icon>
    LOGOUT
  </div>

  <div style="padding: 15px">
    <div class="img-container" style="height: 101px">
      <img alt="Logo" src="assets/branding/logo_lakeshore.png"
           style='height: 100%; object-fit: contain !important; margin: auto'>
    </div>
  </div>

  <div *ngIf="isLoading">
    <div style="min-height: 125px; width: 100%; margin: auto; display: inline-flex">
        <span style="margin: auto">
           <ion-spinner name="circles"></ion-spinner>
        </span>
    </div>
  </div>

  <div *ngIf="selectedSegment === 'awaiting-terms-conditions'"
       style="min-height: 100%;">
    <uab-optin-optout [acceptButtonText]="'Accept Terms and Conditions'"
                      [termsConditionsId]="'8'"
                      (onAccept)="onAcceptingTerms()"
                      [showDeclineButton]="false"
                      [acceptingButtonText]="'acceptingButtonText'">
    </uab-optin-optout>
  </div>

  <div *ngIf="selectedSegment === 'awaiting-speed-check'">
    <ion-card style="padding-top: 20px">
      <uab-speed-check (onComplete)="onSpeedCheck()"
                       [runImmediately]="true"
                       [user]="user"
                       [checkText]="speedCheckText"
                       [checkingText]="speedCheckingText"
                       [nextText]="nextText"
                       [showCheckSpeedButton]="false"
                       [showNextButton]="false">
      </uab-speed-check>
    </ion-card>
  </div>

  <div *ngIf="selectedSegment === 'awaiting-admin-approval'">
    <div>
      <ion-card>
        <ion-card-header style="align-content: center; text-align: center">
          <h1 style="font-weight: bold; font-size: 27px !important" class="lakeshore_paragraph_style">
            You’re almost there! Your profile is awaiting approval.
            This may take up to two business days. Once approved, you will receive an email with next steps and
            instructions. Please click acknowledge to logout…we look forward to seeing you soon!
          </h1>
        </ion-card-header>
        <div id="acknowledge" style="margin: auto; padding-top: 15px">
          <uab-button-group [acceptButtonId]="'logout'"
                            [swapButtonOrder]="true"
                            [acceptText]="'Acknowledge'"
                            [showDeclineButton]="false"
                            (acceptOnClick)="logout()"
                            [acceptActiveText]="'Starting'">
          </uab-button-group>
        </div>
      </ion-card>
    </div>
  </div>

  <div *ngIf="selectedSegment === 'awaiting-user-opt-in-for-study' ||
       selectedSegment === 'user-wants-to-join-study-again'"
       style="min-height: 100%;">

    <ion-card>
      <ion-card-header class="ion-text-center" style="color: #0a0302; font-size: 27px !important; font-weight: bold !important;">
        <div style="white-space: normal">Watch our video to learn more about the Lakeshore Online Fitness study.</div>
      </ion-card-header>
      <div style="margin: 0 auto;text-align: center; max-width: 700px; max-height: 400px;">
        <youtube-player [height]="playerHeight"
                        [videoId]="'q02XeOnxdXU'"
                        (stateChange)="activateDisabledAcceptButton()"
                        [width]="playerWidth">
        </youtube-player>
      </div>
<!--      <div>
        <div>
          <ion-title class="ion-text-center" style="color: #0a0302; font-size: 27px; font-weight: bold !important; margin: 10px !important;">
          </ion-title>
        </div>
        <div style="margin: 0 auto;text-align: center; max-width: 700px; max-height: 400px;">
          <youtube-player [height]="playerHeight"
                          [videoId]="'q02XeOnxdXU'"
                          (stateChange)="activateDisabledAcceptButton()"
                          [width]="playerWidth">
          </youtube-player>
        </div>
      </div>-->
    </ion-card>

    <uab-optin-optout [acceptButtonText]="'Yes I am interested'"
                      [termsConditionsId]="'11'"
                      [acceptButtonTooltip]="'Please Watch Video To Continue'"
                      [declineButtonTooltip]="'Please Watch Video To Continue'"
                      (onAccept)="onAcceptOptIntoStudy()"
                      (onDecline)="onDecliningOptintoStudy()"
                      [disableNextButton]="disableNextButton"
                      [showDisableText]="disableNextButton"
                      [declineButtonText]="'No thanks, not at this time'"
                      [decliningButtonText]="'Declining'"
                      [acceptingButtonText]="'acceptingButtonText'">
    </uab-optin-optout>
  </div>

  <div *ngIf="selectedSegment === 'awaiting-user-research-screening'"
       style="min-height: 100%;">
    <uab-screening-questions (onCancel)="onDeclineResearchScreeningQuestions()"
                             (onCreate)="onCompleteResearchScreeningQuestions($event)"
                             [hardcodedQuestions]="screeningHardcodedQuestions"
                             [surveyName]="screeningQuestionSurveyName">
    </uab-screening-questions>
  </div>

  <div *ngIf="selectedSegment === 'welcome-screen-before-econsent'">
    <div>
      <ion-card>
        <ion-card-content style="margin: auto">
          <div>
            <h1 style="font-weight: bold; font-size: 27px !important" class="lakeshore_paragraph_style text-center">
              Congrats! You qualify to be part of our Lakeshore Online Fitness study.
              Please click to continue.
            </h1>
          </div>
          <div id="next" style="margin: auto; padding-top: 15px">
            <uab-button-group [acceptButtonId]="'Next'"
                              [swapButtonOrder]="true"
                              [acceptText]="'Next'"
                              [showDeclineButton]="false"
                              (acceptOnClick)="advanceToEconsent()"
                              [acceptActiveText]="'Loading'">
            </uab-button-group>
          </div>
        </ion-card-content>
      </ion-card>
    </div>
  </div>

  <div *ngIf="selectedSegment === 'awaiting-user-econsent'"
       style="min-height: 100%;">
    <uab-optin-optout [acceptButtonText]="'I agree to participate'"
                      [termsConditionsId]="'14'"
                      (onAccept)="onAcceptEconsent()"
                      (onDecline)="onDeclineEconsent()"
                      [declineButtonText]="'No thanks'"
                      [decliningButtonText]="'Declining'"
                      [acceptingButtonText]="'acceptingButtonText'">
    </uab-optin-optout>
  </div>

  <div *ngIf="selectedSegment === 'awaiting-user-text-consent'"
       style="min-height: 100%;">
    <uab-optin-optout [acceptButtonText]="'Yes'"
                      [termsConditionsId]="'13'"
                      (onAccept)="onAcceptTextconsent()"
                      (onDecline)="onDecliningTextconsent()"
                      [declineButtonText]="'Decline'"
                      [decliningButtonText]="'Declining'"
                      [acceptingButtonText]="'acceptingButtonText'">
    </uab-optin-optout>
  </div>

  <div *ngIf="selectedSegment === 'awaiting-user-text-preferred-timings'"
       style="min-height: 100%;">
    <ion-row>
      <ion-col [size]="12"
               [sizeLg]="12">
        <div>
          <div class="home_page_titles">
            If study staff need to contact via phone, What time do you prefer to be contact?
          </div>

          <div>
            <ion-row>
              <ion-col [size]="12">
                <ion-button color="primary" (click)="setAnytimeOfDay()">
                  Anytime during business hours
                </ion-button>
              </ion-col>
              <ion-col [size]="12">
                <ion-button color="primary" (click)="expandToChooseTime()">
                  Choose a specific time
                </ion-button>
              </ion-col>
            </ion-row>
          </div>
        </div>

        <div *ngIf="showChoosetimeBlock">
          <div *ngIf="contactPreferenceIsDirty() && !checkContactPreference() "
               style="color: red; margin-left: 20px;">
            Time out of range (9AM - 6PM).
          </div>

          <div style="width: 100%;">
            <div style="align-content: center; text-align: center; margin: auto">
              <uab-time [id]="'timePreference'"
                        [required]="true"
                        [placeholder]="contactTimePreferencePlaceholder"
                        [label]="'Please select a time between 9 AM and 6 PM'"
                        [model]="activeUser.preferred_contact_time"
                        (modelChange)="checkContactPreference($event)"></uab-time>
            </div>
          </div>
        </div>
        <div>
          <uab-button-group [acceptButtonId]="'Continue'"
                            [swapButtonOrder]="true"
                            [acceptText]="'Continue'"
                            [showDeclineButton]="false"
                            (acceptOnClick)="advanceToWelcomeScreen()"
                            [acceptActiveText]="'Starting'">
          </uab-button-group>
        </div>
      </ion-col>
    </ion-row>
  </div>

  <div *ngIf="selectedSegment === 'welcome-screen-before-surveys'">
    <div>
      <ion-card>
        <ion-card-content style="margin: auto">
          <div>
            <h1 style="font-weight: bold; font-size: 27px !important" class="lakeshore_paragraph_style text-center">
              Welcome! To continue in the study, we ask you to complete a series of
              online surveys now and after 8 weeks of participation.
              The surveys contain questions related to your current level of physical activity, balance,
              quality of life and other exercise related surveys. Completion of the surveys will
              take approximately 30 minutes. You may pause the surveys and take a break at any time by
              exiting the browser. Your survey responses will be saved and the next time you login,
              you can pick up where you left off. After completing the entire survey packet,
              you will be sent a gift card via email and be provided with access to the online class registration system.
            </h1>
          </div>
          <div id="surveys" style="margin: auto; padding-top: 15px">
            <uab-button-group [acceptButtonId]="'take-surveys'"
                              [swapButtonOrder]="true"
                              [acceptText]="'Start Surveys'"
                              [showDeclineButton]="false"
                              (acceptOnClick)="onTakingSurvey()"
                              [acceptActiveText]="'Starting'">
            </uab-button-group>
          </div>
        </ion-card-content>
      </ion-card>
    </div>
  </div>

  <div *ngIf="selectedSegment === 'user-failed-research-screening'">
    <div>
      <ion-card>
        <ion-card-content style="margin: auto">
          <div>
            <h1 style="font-weight: bold; font-size: 27px !important" class="lakeshore_paragraph_style text-center">
              {{ declinedScreenText }} Please continue to enjoy the Lakeshore Online Fitness program.
            </h1>
          </div>
          <div id="home" style="margin: auto; padding-top: 15px">
            <uab-button-group [acceptButtonId]="'start-the-study'"
                              [swapButtonOrder]="true"
                              [acceptText]="'GO TO HOME PAGE'"
                              [showDeclineButton]="false"
                              (acceptOnClick)="advanceToStudy()"
                              [acceptActiveText]="'Starting'">
            </uab-button-group>
          </div>
        </ion-card-content>
      </ion-card>
    </div>
  </div>

  <div *ngIf="showSurveyModal">
    <div>
      <ion-card>
        <ion-card-header style="align-content: center; text-align: center">
          <h1 style="font-weight: bolder">
            Post Intervention Survey
          </h1>
        </ion-card-header>
        <ion-card-content style="margin: auto">
          <div>
            <h1 style="font-weight: bold; font-size: 27px !important" class="lakeshore_paragraph_style text-center">
              You can complete the post survey now or later, please click the appropriate button
            </h1>
          </div>
          <div>
            <uab-button-group [swapButtonOrder]="true"
                              [acceptButtonId]="'start-the-study'"
                              [acceptText]="'Take The Post Survey'"
                              [declineText]="'Complete Later'"
                              [showDeclineButton]="true"
                              [declineActiveText]="'Declining'"
                              (acceptOnClick)="onTakingPostSurvey()"
                              (declineOnClick)="onDecliningCompletePostSurvey()"
                              [acceptActiveText]="'Starting'">
            </uab-button-group>
          </div>
        </ion-card-content>
      </ion-card>
    </div>
  </div>
</ion-card>



