import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';

import {Router, RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ServiceWorkerModule} from '@angular/service-worker';

import {StatusBar} from '@ionic-native/status-bar/ngx';

import * as Sentry from '@sentry/angular';
import {TraceService} from '@sentry/angular';

import {NgPluralizeModule} from 'ng-pluralize';

import {environment} from '../environments/environment';

import {
  AccessibilityService,
  AddCorsInterceptor,
  AddDebugInterceptor,
  AddNoCacheInterceptor,
  AddTokenInterceptor,
  AnalyticStateVisitService,
  ColorTemplateService,
  EnvironmentService,
  LingoService,
  NextBaseModule,
  SettingsService
} from '@uab.lakeshore.collaborative/next-base';

import {NextFormsModule, StateListService} from '@uab.lakeshore.collaborative/next-forms';
import {NextNotificationsModule} from '@uab.lakeshore.collaborative/next-notifications';
import {BrowserBlockModule, NextTechModule} from '@uab.lakeshore.collaborative/next-tech';
import {NextVideosModule} from '@uab.lakeshore.collaborative/next-videos';
import {PreviousRouteService} from './services/previousRoute.service';
import {AppComponent} from './app.component';

import {AppRoutingModule} from './app-routing.module';

import {ChunkErrorHandler} from './components/chunk-error-handler';

import {ArticleService} from './services/article.service';
import {PwaService} from './services/pwa.service';
import {RegisterService} from './services/register.service';
import {SecurityQuestionService} from './services/security-question.service';
import {TagService} from './services/tag.service';
import {UserDurationService} from './services/user-duration.service';
import {UserStatusService} from './services/user-status.service';
import {UserTypeService} from './services/user-type.service';

import {MenuListModule} from './components/menu-list/menu-list.module';
import {CompleteProfileModalModule} from './components/complete-profile-modal/complete-profile-modal.module';
import {IsAtLeastAdminGuard} from './guards/is-at-least-admin.guard';
import {IsAtLeastLakeshoreAdminGuard} from './guards/is-at-least-lakeshore-admin.guard';
import {IsSurveysCompleted} from './guards/is-surveys-completed';

export function ConfigLoader(
  colorTemplateService:ColorTemplateService,
  environmentService:EnvironmentService,
  lingoService:LingoService
) {
  return () => {
    environmentService.setAll(environment);

    colorTemplateService.updateSelectors(
      {
        'id': '3',
        'created': null,
        'modified': null,
        'accept_button_color': '#005F9B',
        'accept_button_text_color': null,
        'decline_button_color': null,
        'decline_button_text_color': null,
        'neutral_button_color': null,
        'neutral_button_text_color': null,
        'primary_color': '#005F9B',
        'primary_text_color': null,
        'secondary_color': null,
        'secondary_text_color': null
      }
    );

    lingoService.init(environment.backendUrl).subscribe();

    return new Promise((resolve) => {
      resolve(true);
    });
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  exports: [],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    BrowserBlockModule,
    CompleteProfileModalModule,
    FormsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    MenuListModule,
    NextBaseModule.forRoot(),
    NextFormsModule.forRoot(),
    NextNotificationsModule.forRoot(),
    NextTechModule.forRoot(),
    NextVideosModule.forRoot(),
    NgPluralizeModule,
    ServiceWorkerModule.register(
      'ngsw-worker.js',
      {
        enabled: environment.environment !== 'local'
      }
    ),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.environment !== 'local'})
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ChunkErrorHandler
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: TraceService,
      deps: [
        Router
      ],
    },
    {
      deps: [
        ColorTemplateService,
        EnvironmentService,
        LingoService
      ],
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: ConfigLoader
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddCorsInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddDebugInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddNoCacheInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddTokenInterceptor,
      multi: true
    },
    AccessibilityService,
    AnalyticStateVisitService,
    ArticleService,
    IsAtLeastAdminGuard,
    IsAtLeastLakeshoreAdminGuard,
    PwaService,
    PreviousRouteService,
    RegisterService,
    SecurityQuestionService,
    SettingsService,
    StateListService,
    StatusBar,
    TagService,
    UserDurationService,
    UserStatusService,
    UserTypeService,
    IsSurveysCompleted
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
