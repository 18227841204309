import {Component, OnInit, HostListener} from '@angular/core';

import {
  ActiveUser,
  AlertService,
  BaseComponent,
  DateService,
  EnvironmentService,
  LingoService,
  LoginService,
  PlatformService,
  RedirectService,
  RequestService,
  RouteService,
  Survey,
  User,
  UserInterface,
  UserService,
  VariableService
} from '@uab.lakeshore.collaborative/next-base';
import {SurveyService} from '@uab.lakeshore.collaborative/next-surveys';
import {ConfirmationController} from '@uab.lakeshore.collaborative/next-base';
import {RegisterService} from '../../services/register.service';
import {TermsConditionsUsersModel} from '../../models/terms-conditions-users.model';
import {TermsConditionsUsersService} from '../../services/terms-conditions-users.service';
import {HardcodedQuestionModel} from '../../models/hardcoded-question.model';
import {UserDurationService} from '../../services/user-duration.service';
import {SharedSubscriptionService} from '../../services/sharedSubscription.service';
import {Subscription} from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'uab-complete-profile-modal',
  templateUrl: 'complete-profile-modal.component.html',
  styleUrls: [
    'complete-profile-modal.component.scss'
  ]
})
export class CompleteProfileModalComponent implements BaseComponent, OnInit {
  public activeUser:ActiveUser;
  public showModal:boolean;
  public showSurveyModal:boolean = false;

  public termsAndConditionsUserModel:TermsConditionsUsersModel = new TermsConditionsUsersModel();
  public contactPreferenceIsValid:boolean = false;
  public isLoading:boolean = false;
  public showChoosetimeBlock:boolean = false;
  public declinedScreenText:string = 'Sorry, you are not eligible for study. ';
  public backText:string;
  public bioPlaceholderText:string;
  public cancelText:string;
  public confirmPasswordText:string;
  public contactTimePreferenceText:string;
  public createButtonText:string;
  public dobText:string;
  public displayNamePlaceholderText:string;
  public emailText:string;
  public emailOrTextPreferenceText:string;
  public goBackText:string;
  public nicknameText:string;
  public pageTitle:string;
  public passwordText:string;
  public processingText:string;
  public profileImageUploadText:string;
  public contactSubmitText:string;
  public profileSubmitText:string;
  public contactSubmittingText:string;
  public passwordSubmitText:string;
  public saveText:string;
  public savingText:string;
  public text:string;
  public warningText:string;
  public user:UserInterface;
  public userCurrentWeek:number;
  public disableNextButton:boolean = true;

  public transactionHappening:boolean = false;

  public speedCheckText:string;
  public speedCheckingText:string;
  public nextText:string;

  public selectedSegment:string;
  public index:number;
  public preSurveyIds:Array<number> = [];
  public moment:typeof moment = moment;
  public survey:Survey;
  public contactTimePreferenceLabel:string = 'Please select a preferred contact time if the study staff needs to reach you out via phone call.';
  public contactTimePreferencePlaceholder:string = 'Preferred Contact Time';
  public screeningQuestionSurveyName:string;
  public screeningHardcodedQuestions:Array<any> = [];
  public question1:HardcodedQuestionModel = new HardcodedQuestionModel();
  public question2:HardcodedQuestionModel = new HardcodedQuestionModel();
  public question3:HardcodedQuestionModel = new HardcodedQuestionModel();
  public question4:HardcodedQuestionModel = new HardcodedQuestionModel();
  public question5:HardcodedQuestionModel = new HardcodedQuestionModel();

  public invokeEventSubscription:Subscription;
  public playerWidth:number;
  public playerHeight:number;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    const scrWidth = window.innerWidth;
    if (scrWidth >= 540) {
      this.playerWidth = 500;
      this.playerHeight = 300;
    }
    if (scrWidth >= 360 && scrWidth < 539) {
      this.playerWidth = 320;
      this.playerHeight = 260;
    }
    if (scrWidth < 360 && scrWidth >= 300) {
      this.playerWidth = 280;
      this.playerHeight = 240;
    }
    if (scrWidth < 300) {
      this.playerWidth = 250;
      this.playerHeight = 200;
    }
  }

  constructor(
    private alert:AlertService,
    private confirm:ConfirmationController,
    private date:DateService,
    private environment:EnvironmentService,
    private lingo:LingoService,
    private login:LoginService,
    private platformService:PlatformService,
    private request:RequestService,
    private register:RegisterService,
    private redirectService:RedirectService,
    private route:RouteService,
    private service:SurveyService,
    private sharedSubscriptionService:SharedSubscriptionService,
    private userService:UserService,
    private variable:VariableService,
    private termsConditionsUsersService:TermsConditionsUsersService,
    private userDurationService:UserDurationService,
  ) {
    this.getScreenSize();

    this.loadLingo();

    this.reset();
  }

  showOptIntoStudyQuestion() {
    this.register.getOptinTermsAndConditionsOfUser(this.activeUser.id).subscribe(
      (response:any) => {
        if (!response.errors) {
          if (response.data.length === 0) {
            this.selectedSegment = 'opt-in';
          }
        }
      }
    );
  }

  activateDisabledAcceptButton() {
    this.disableNextButton = false;
  }

  loadActiveUser():void {
    const activeUser = this.login.getActiveUser();

    if (activeUser) {
      this.activeUser = <ActiveUser><unknown>activeUser;

      this.userCurrentWeek = this.userDurationService.getWeeks(
        this.activeUser
      );

      const showModalConditionsArray = ['awaiting-terms-conditions', 'awaiting-admin-approval', 'awaiting-user-econsent',
        'awaiting-user-opt-in-for-study', 'awaiting-survey-complete', 'awaiting-user-text-consent',
        'awaiting-user-text-preferred-timings', 'awaiting-user-research-screening', 'user-wants-to-join-study-again',
        'welcome-screen-before-surveys', 'welcome-screen-before-econsent', 'awaiting-speed-check'];

      this.showModal = showModalConditionsArray.some(
        (status) => {
          return status === (<ActiveUser>this.activeUser).status;
        }
      );

      if (this.showModal) {
        this.selectedSegment = (<ActiveUser>this.activeUser).status;
      }

      if ((this.userCurrentWeek > 8) && (this.userCurrentWeek <= 10) ) {
        console.log('status is: ' + (this.activeUser.status.search('awaiting-post-intervention-survey-complete') !== -1));
        if ((this.activeUser.status.search('awaiting-post-intervention-survey-complete') !== -1)) {
          this.showModal = true;
          this.showSurveyModal = true;
        } else if (this.activeUser.user_type_name === 'research_participant') {

          if ((this.activeUser.status === 'pre-survey-completed')) {
            this.alert.presentToast(
              'Thank you for completing the Lakeshore Online Fitness research study! ' +
              'Please complete the surveys and you will receive a gift card via mail. ' +
              'Thanks again, and keep up your physical activity!', 'primary', 12000
            ).then();
          }

          if ((this.activeUser.status !== 'post-survey-completed') || (this.activeUser.status.search('awaiting-post-intervention-survey-complete') !== -1)) {
            this.activeUser.status = 'awaiting-post-intervention-survey-complete-303';

            this.userService.editUser(this.activeUser).subscribe(
              (response) => {
                if (response) {
                 window.location.reload();
                }
              }
            );
          }
        }
      }

      if (this.showModal && this.userCurrentWeek <= 8) {
        this.alert.presentToast('Welcome! Before using the application you must fill in the information below.', 'dark').then();
      }
    }
  }

  loadLingo():void {
    this.backText = this.lingo.get(
      'complete-profile.modal.button.cancel',
      'Previous'
    );

    this.bioPlaceholderText = this.lingo.get(
      'complete-profile.modal.bio.placeholder',
      'Enter a short bio...'
    );

    this.cancelText = this.lingo.get(
      'complete-profile.modal.button.cancel',
      'Save & Exit'
    );

    this.confirmPasswordText = this.lingo.get(
      'complete-profile.modal.confirm-password.input.text',
      'Confirm Password'
    );

    this.contactTimePreferenceText = this.lingo.get(
      'complete-profile.modal.contact.time.preference.text',
      'What time do you prefer to be contact?'
    );

    this.createButtonText = this.lingo.get(
      'complete-profile.modal.create.button.text',
      'Create'
    );

    this.dobText = this.lingo.get(
      'complete-profile.modal.dob.text',
      'Date of Birth'
    );

    this.displayNamePlaceholderText = this.lingo.get(
      'complete-profile.modal.display.name.placeholder.text',
      'Display Name..'
    );

    this.emailText = this.lingo.get(
      'complete-profile.modal.email.text',
      'email'
    );

    this.emailOrTextPreferenceText = this.lingo.get(
      'complete-profile.modal.email.preference.text',
      'Do you prefer email or text?'
    );

    this.goBackText = this.lingo.get(
      'complete-profile.modal.button.back',
      'Back'
    );

    this.nicknameText = this.lingo.get(
      'complete-profile.modal.display.name.text',
      'Nickname'
    );

    this.pageTitle = this.lingo.get(
      'complete-profile.modal.title',
      'Complete Profile'
    );

    this.passwordText = this.lingo.get(
      'complete-profile.modal.password.input.text',
      'Password'
    );

    this.processingText = this.lingo.get(
      'complete-profile.modal.processing.text',
      'Processing...'
    );

    this.profileImageUploadText = this.lingo.get(
      'complete-profile.modal.profile.image.text',
      'Upload Profile Image'
    );

    this.contactSubmitText = this.lingo.get(
      'complete-profile.modal.submit.contact',
      'Next'
    );

    this.profileSubmitText = this.lingo.get(
      'complete-profile.modal.submit.contact',
      'Complete'
    );

    this.contactSubmittingText = this.lingo.get(
      'complete-profile.modal.submit.contact.saving',
      'Saving'
    );

    this.passwordSubmitText = this.lingo.get(
      'complete-profile.modal.submit.password',
      'Next'
    );

    this.text = this.lingo.get(
      'complete-profile.modal.text',
      'text'
    );

    this.warningText = this.lingo.get(
      'complete-profile.modal.password.warning.text',
      'Passwords did not match'
    );

    this.saveText = this.lingo.get(
      'complete-profile.modal.button.save',
      'Save'
    );

    this.savingText = this.lingo.get(
      'complete-profile.modal.button.saving',
      'Saving...'
    );

    this.speedCheckText = this.lingo.get(
      'complete-profile.speed.check.label',
      'Check Internet'
    );

    this.speedCheckingText = this.lingo.get(
      'complete-profile.speed.check.label',
      'Checking...'
    );

    this.nextText = this.lingo.get(
      'complete-profile.button.continue.label',
      'Continue'
    );
  }

  logout():void {
    this.login.logout().subscribe();
  }

  getCriteriaCondition(answer) {
    const showModalConditionsArray = ['Some difficulty', 'Much difficulty', 'Unable to do', 'Yes'];

    let criteria = false;

    criteria = showModalConditionsArray.some(
      (status) => {
        return status === answer;
      }
    );

    return criteria;
  }

  onCompleteResearchScreeningQuestions(activeUser:any) {
    this.termsAndConditionsUserModel.user_id = this.activeUser.id;
    this.isLoading = true;

    if (this.getCriteriaCondition(activeUser.requiring_special_equipment) ||
        this.getCriteriaCondition(activeUser.impairment_limiting_walk) ||
        this.getCriteriaCondition(activeUser.difficulty_without_special_equipment) ||
        this.getCriteriaCondition(activeUser.difficulty_standing_armless_chair) ||
        this.getCriteriaCondition(activeUser.difficulty_eating_holding_cutting_glass)) {
      this.editActiveUserWithStatusAndSelectSegment('welcome-screen-before-econsent', 'welcome-screen-before-econsent', false);
    } else {
      this.activeUser.user_type_name = 'lakeshore_participant';
      this.activeUser.user_type_id = '183';
      this.declinedScreenText = 'Thank you for your interest in the research component of Lakeshore Online ' +
        'Fitness. Unfortunately, you do not qualify for the study, but you may still use all of the features of ' +
        'Lakeshore Online Fitness.';
      this.editActiveUserWithStatusAndSelectSegment('user-failed-research-screening', 'user-failed-research-screening', false);
    }
  }

  onAcceptOptIntoStudy() {
    this.termsAndConditionsUserModel.user_id = this.activeUser.id;
    this.termsAndConditionsUserModel.terms_and_condition_id = 11;
    this.isLoading = true;

    this.termsConditionsUsersService.createTermsandConditionsUserToOptintoStudy(this.termsAndConditionsUserModel).subscribe(
      (response:any) => {
        if (!response.errors) {
          this.editActiveUserWithStatusAndSelectSegment('awaiting-user-research-screening', 'awaiting-user-research-screening', false);
        } else {
          this.alert.addMessage('Unable to Opt into study. Please try again');
        }
      }
    );
  }

  onTakingSurvey() {
    this.activeUser.status = 'awaiting-survey-complete-287';

    this.userService.editUser(this.activeUser).subscribe(
      (response) => {
        if (response) {
          this.showModal = false;

          window.location.reload();
        }
      }
    );
  }

  onTakingPostSurvey() {
    this.showModal = false;
    this.showSurveyModal = false;
    this.redirectService.goTo('post-intervention-surveys');
  }

  onAcceptEconsent() {
    this.termsAndConditionsUserModel.user_id = this.activeUser.id;
    this.termsAndConditionsUserModel.terms_and_condition_id = 14;
    this.isLoading = true;

    this.termsConditionsUsersService.createTermsandConditionsUserToOptintoStudy(this.termsAndConditionsUserModel).subscribe(
      (response:any) => {
        if (!response.errors) {
          this.editActiveUserWithStatusAndSelectSegment('awaiting-user-text-consent', 'awaiting-user-text-consent', false);
        } else {
          this.alert.addMessage('Unable to confirm the E consent. Please try again');
        }
      }
    );
  }

  onDeclineEconsent() {
    this.declinedScreenText = 'Sorry, You declined consent and cannot be part of research. You can always come back. ';
    this.isLoading = true;
    this.activeUser.start_time = this.date.toDatabaseFormat();
    this.activeUser.user_type_name = 'lakeshore_participant';
    this.activeUser.user_type_id = '183';
    this.editActiveUserWithStatusAndSelectSegment('user-declined-e-consent', 'user-failed-research-screening', false);
  }

  onAcceptTextconsent() {
    this.termsAndConditionsUserModel.user_id = this.activeUser.id;
    this.termsAndConditionsUserModel.terms_and_condition_id = 13;
    this.isLoading = true;

    this.termsConditionsUsersService.createTermsandConditionsUserToOptintoStudy(this.termsAndConditionsUserModel).subscribe(
      (response:any) => {
        if (!response.errors) {
          this.activeUser.preferred_contact_type = 'text';
          this.editActiveUserWithStatusAndSelectSegment('awaiting-user-text-preferred-timings',
            'awaiting-user-text-preferred-timings', false);
        } else {
          this.alert.addMessage('Unable to Opt into study. Please try again');
        }
      }
    );
  }

  onDecliningTextconsent() {
    // user declined the text consent
    this.editActiveUserWithStatusAndSelectSegment('awaiting-user-text-preferred-timings',
      'awaiting-user-text-preferred-timings', false);
  }

  onAcceptTextPreferredTimings() {
    //
  }

  onDecliningTextPreferredTimings() {
    //
  }

  editActiveUserWithStatusAndSelectSegment(status:string, segment?:string, hideModal?:boolean) {
    this.transactionHappening = true;

    if (hideModal) {
      this.showModal = false;
    } else if (status === '') {
      this.selectedSegment = segment;
    }

    if (status !== '') {
      this.activeUser.status = status;
    }

    // TODO Kireeti
    if (status !== '' && status !== 'awaiting-post-intervention-survey-complete' && status !== 'pre-survey-completed') {
      this.activeUser.start_time = this.moment().format('YYYY-MM-DD HH:mm:ss');
      this.userService.editUser(this.activeUser).subscribe(
        (response) => {
          if (response) {
            this.isLoading = false;

            this.selectedSegment = segment;
          }
        }
      );
    }

    // TODO VENkat
    if (status === 'awaiting-post-intervention-survey-complete') {
      this.selectedSegment = segment;
    }

    this.transactionHappening = false;
  }

  onAcceptingTerms() {
    this.isLoading = true;
    this.editActiveUserWithStatusAndSelectSegment('awaiting-speed-check', 'awaiting-speed-check', false);
  }

  contactPreferenceIsDirty():boolean {
    return typeof this.activeUser.preferred_contact_time !== 'undefined'
      && this.activeUser.preferred_contact_time !== null;
  }

  checkContactPreference(preference?:string):boolean {
    if (this.variable.isString(preference)) {
      preference = preference.replace('undefined', '');
      preference = preference.replace(' am', '');
      preference = preference.replace(' pm', '');
      preference = preference.trim();

      if (preference.indexOf('-') !== -1) {
        const moment = this.date.moment(preference);

        const hour = moment.hour();
        const minute = moment.minute();

        preference = hour + ':' + minute;
      }

      this.activeUser.preferred_contact_time = preference;
    }

    preference = this.variable.getString(
      this.activeUser.preferred_contact_time
    );

    console.log('BEFORE: ' + preference);

    preference = this.date.toTwelveHourTime(preference);

    console.log('AFTER: ' + preference);

    const today = this.date.moment().format('YYYY/MM/DD');

    const timeMoment = this.date.moment(
      today + ' ' + preference
    );

    const isAfterMin = timeMoment.isAfter(
      this.date.moment(today + ' 8:59 am')
    );

    const isBeforeMax = timeMoment.isBefore(
      this.date.moment(today + ' 6:01 pm')
    );

    this.contactPreferenceIsValid = isAfterMin && isBeforeMax;

    return this.contactPreferenceIsValid;
  }


  onSpeedCheck():void {
    const speed = this.variable.getInteger(
      this.user.speed_check,
      -1
    );

    if (speed > 1) {
      this.editActiveUserWithStatusAndSelectSegment('awaiting-admin-approval', 'awaiting-admin-approval', false);
    } else {
      this.alert.addMessage('Your internet condition is unstable, please switch to a stable network');
      // this.showModal = false;
    }
  }

  showTermsAndConditions() {
    this.selectedSegment = 'terms';
  }

  onCompleteSurvey() {
    if (this.userCurrentWeek > 8) {
      this.editActiveUserWithStatusAndSelectSegment('user-completed-post-intervention-survey', 'no-segment', true);
    }

    this.advanceToStudy();

    this.editActiveUserWithStatusAndSelectSegment('user-completed-pre-surveys', 'no-segment', true);
  }

  onDeclineResearchScreeningQuestions() {
    this.activeUser.user_type_name = 'lakeshore_participant';
    this.activeUser.user_type_id = '183';
    this.declinedScreenText = 'Sorry, you are not interested to answer the questions. ';
    this.editActiveUserWithStatusAndSelectSegment('user-declined-research-screening', 'user-failed-research-screening', false);
  }

  onDecliningOptintoStudy() {
    this.declinedScreenText = 'Thank you for your response, please let us know if you decide later that you would like to participate in the study. ';
    this.isLoading = true;
    this.activeUser.start_time = this.moment().format('YYYY-MM-DD HH:mm:ss');
    this.activeUser.user_type_name = 'lakeshore_participant';
    this.activeUser.user_type_id = '183';
    this.editActiveUserWithStatusAndSelectSegment('user-declined-opt-into-study', 'user-failed-research-screening', false);
  }

  onDecliningCompletePostSurvey() {
   this.showSurveyModal = false;
   this.showModal = false;
    this.redirectService.goTo('home');
  }

  advanceToWelcomeScreen() {
    this.isLoading = true;
    this.editActiveUserWithStatusAndSelectSegment('welcome-screen-before-surveys', 'welcome-screen-before-surveys', false);
  }

  advanceToSurveys() {
    this.editActiveUserWithStatusAndSelectSegment('awaiting-survey-complete', 'awaiting-survey-complete', false);
  }

  advanceToEconsent() {
    this.editActiveUserWithStatusAndSelectSegment('awaiting-user-econsent', 'awaiting-user-econsent', false);
  }

  advanceToStudy() {
    this.editActiveUserWithStatusAndSelectSegment('', 'no-segment', true);

    window.location.reload();
  }

  advanceToPostSurvey() {
    this.editActiveUserWithStatusAndSelectSegment('awaiting-post-intervention-survey-complete',
      'awaiting-post-intervention-survey-complete', false);
  }

  setAnytimeOfDay() {
    this.activeUser.preferred_contact_time = null;
    this.showChoosetimeBlock = false;

    this.alert.presentToast('Contact time set', 'dark').then();

    this.advanceToWelcomeScreen();
  }

  expandToChooseTime() {
    this.showChoosetimeBlock = true;
  }

  reset():void {
    this.showModal = false;

    this.preSurveyIds = [];

    this.user = new User();

    this.selectedSegment = '';

    this.screeningQuestionSurveyName = 'Research Screening Questionnaire';

    this.question1.phrasing = 'Do you have an impairment or health problem that limits your ability to walk or run?';
    this.question1.answer_options = ['Yes', 'No'];
    this.question1.modelName = 'impairment_limiting_walk';
    this.question1.id = '1';

    this.question2.phrasing = 'Do you have any impairment or health ' +
      'problem that requires you to use an assistive device, such as a brace, cane, crutches, walkers, or wheelchair?';
    this.question2.answer_options = ['Yes', 'No'];
    this.question2.modelName = 'requiring_special_equipment';
    this.question2.id = '2';

    this.question3.phrasing = 'Because of a health problem, do you have ' +
      'difficulty walking without using any assistive  device?';
    this.question3.answer_options = ['Yes', 'No'];
    this.question3.modelName = 'difficulty_without_special_equipment';
    this.question3.id = '3';

    this.question4.phrasing = 'By yourself and without using an assistive device, ' +
      'how much difficulty do you have standing up from an armless straight chair?';
    this.question4.answer_options = ['No difficulty', 'Some difficulty', 'Much difficulty',
      'Unable to do'];
    this.question4.modelName = 'difficulty_standing_armless_chair';
    this.question4.id = '4';

    this.question5.phrasing = 'By yourself and without using an assistive device, how much difficulty do you have eating, ' +
      'like holding a fork, cutting food or drinking from a glass?';
    this.question5.answer_options = ['No difficulty', 'Some difficulty', 'Much difficulty',
      'Unable to do'];
    this.question5.modelName = 'difficulty_eating_holding_cutting_glass';
    this.question5.id = '5';

    this.screeningHardcodedQuestions = [this.question1, this.question2,
      this.question3, this.question4, this.question5];

    this.loadActiveUser();
  }

  ngOnInit() {
    if (this.activeUser.status.search('awaiting-survey-complete-') !== -1) {
      this.redirectService.goTo('pre-intervention-surveys').then();
    }
  }
}
