<ion-card *ngIf="isLoading"
          class="terms-and-conditions-skeleton">
  <div *ngFor="let range of [] | range:3">
    <uab-skeleton-paragraph></uab-skeleton-paragraph>

    <ion-item-divider></ion-item-divider>
  </div>
</ion-card>

<ion-card *ngIf="!isLoading"
          class="terms-and-conditions">
  <div *ngFor="let tc of termsAndConditions"
       class="ion-margin">
    <ion-text>
      <div [innerHTML]="tc.terms | safeHtml"></div>
    </ion-text>
  </div>

  <ion-item-divider></ion-item-divider>

  <uab-button-group (acceptOnClick)="onAccept.emit()"
                    [acceptActiveText]="acceptingButtonText"
                    [acceptActive]="isAcceptingTerms"
                    [acceptButtonId]="'terms-accept-button'"
                    [acceptText]="acceptButtonText"
                    [swapButtonOrder]="true">
  </uab-button-group>

</ion-card>
