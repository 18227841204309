import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {MenuListComponent} from './menu-list.component';

import {
  ConfirmationModule,
  EnvironmentService,
  ListEndCapModule,
  RangeModule,
  SkeletonBlockModule
} from '@uab.lakeshore.collaborative/next-base';
import {MenuFeatureButtonModule} from '../menu-feature-button/menu-feature-button.module';
import {UserNameCardModule} from '../user-name-card/user-name-card.module';

import {FeatureUserTypeService} from '../../services/feature-user-type.service';
import {ConfirmationController} from '@uab.lakeshore.collaborative/next-base';
import {SpeedCheckinsService} from '../../services/speed-checkins.service';
import {FooterModule} from '../footer/footer.module';

@NgModule({
  declarations: [
    MenuListComponent
  ],
  exports: [
    MenuListComponent
  ],
  imports: [
    CommonModule,
    ConfirmationModule,
    FormsModule,
    FooterModule,
    IonicModule,
    ListEndCapModule,
    MenuFeatureButtonModule,
    RangeModule,
    SkeletonBlockModule,
    UserNameCardModule
  ],
  providers: [
    ConfirmationController,
    EnvironmentService,
    FeatureUserTypeService,
    SpeedCheckinsService
  ]
})
export class MenuListModule {
}
