import {User} from '@uab.lakeshore.collaborative/next-base';

export class ActiveUserModel extends User {
  public video_start_time:null | string;
  public lakeshore_membership:null | string;

  constructor() {
    super();
  }
}
