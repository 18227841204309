import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';

import {
  BaseCrudService,
  DateService,
  EnvironmentService,
  HttpService,
  ListService,
  LocalStorageService,
  LoginService,
  Request,
  RequestService,
  Response,
  StringService,
  User,
  VariableService
} from '@uab.lakeshore.collaborative/next-base';

@Injectable()
export class RegisterService extends BaseCrudService {
  public model = false;

  constructor(
    private date:DateService,
    private variable:VariableService,
    private environment:EnvironmentService,
    private local:LocalStorageService,
    private login:LoginService,
    private list:ListService,
    private request:RequestService,
    private string:StringService,
    private httpService:HttpService
  ) {
    super(
      variable,
      environment,
      list,
      request,
      string
    );
  }

  registerUser(newUser:User, request?:Request) {
    request = this.request.getRequest(request);

    request = this.request.setPrefix(request, 'onboarding');
    request = this.request.setModel(request, 'users');
    request = this.request.setAction(request, 'register');

    return super._create(newUser, request);
  }

  getTermsAndConditions(request?:Request):Observable<Response> {
    request = this.request.getRequest(request);

    request = this.request.setPrefix(request, 'onboarding');
    request = this.request.setModel(request, 'terms_and_conditions');

    request = this.request.setLimit(request, 100);

    return super._getMore(request);
  }

  getOptinTermsAndConditionsOfUser(activeUserId) {
    let request = this.request.getRequest();

    request = this.request.setModel(request, 'terms_and_conditions_users');

    request = this.request.setWheres(
      request,
      [
        'user_id=' + activeUserId,
        'terms_and_condition_id=11'
      ]
    );

    return super._getMore(request);
  }

  getColumns(request?:Request):Observable<Response> {
    return super._getColumns(request);
  }

  getMore(request?:Request) {
    return this.getTermsAndConditions(request);
  }

  getPage(request?:Request) {
    return this.getTermsAndConditions(request);
  }
}
