import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {MenuFeatureButtonComponent} from './menu-feature-button.component';
import {ConfirmationModule} from '@uab.lakeshore.collaborative/next-base';

@NgModule({
  imports: [
    CommonModule,
    ConfirmationModule,
    FormsModule,
    IonicModule,
    RouterModule
  ],
  declarations: [
    MenuFeatureButtonComponent
  ],
  exports: [
    MenuFeatureButtonComponent
  ],
  providers: []
})
export class MenuFeatureButtonModule {
}
